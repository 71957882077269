<template>
  <v-container fluid fill-height>
    <v-overlay :value="overlayLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-row no-gutters cols="12" align="center" justify="center">
      <v-col sm="12" md="6" lg="4">
        <v-card class="elevation-10" shaped>
          <v-toolbar dark>
            <v-toolbar-title class="title">Login</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-card-text>
                <v-text-field
                  ref="username"
                  v-on:keyup.enter="login"
                  v-model="username"
                  prepend-icon="mdi-account"
                  name="username"
                  label="Username"
                  type="text"
                ></v-text-field>
                <v-text-field
                  v-on:keyup.enter="login"
                  v-model="password"
                  prepend-icon="mdi-lock"
                  name="password"
                  label="Password"
                  id="password"
                  :append-icon="hiddenMode ? 'visibility' : 'visibility_off'"
                  @click:append="() => (hiddenMode = !hiddenMode)"
                  :type="hiddenMode ? 'password' : 'text'"
                ></v-text-field>
              </v-card-text>
            </v-window-item>

            <v-window-item :value="2">
              <v-card-text>
                <v-text-field
                  v-on:keyup.enter="login"
                  v-model="code"
                  name="code"
                  ref="code"
                  prepend-icon="mdi-lock"
                  label="6-digit code"
                  autocomplete="off"
                  type="text"
                ></v-text-field>
              </v-card-text>
            </v-window-item>
          </v-window>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              width="77"
              dark
              rounded
              @click.stop="login"
              :loading="loading"
            >
              Login
            </v-btn>
          </v-card-actions>
          <v-alert
            v-model="alert"
            transition="slide-y-transition"
            type="error"
            dismissible
          >
            Failed to login. Please check your credentials.
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Login from "@/services/LoginService.js";

export default {
  data: () => ({
    overlayLoading: false,
    loading: false,
    alert: false,
    username: process.env.VUE_APP_USERNAME || "",
    password: process.env.VUE_APP_PASSWORD || "",
    code: "",
    step: 1,
    color: "error",
    hiddenMode: true
  }),
  methods: {
    async login() {
      this.loading = true;
      this.alert = false;
      try {
        const res = await Login.sendCredentials({
          username: this.username,
          password: this.password,
          code: this.code
        });
        if (res.data.success) {
          this.$router.replace("/");
        } else {
          this.alert = true;
        }
      } catch (err) {
        if (
          err?.response?.data?.message ===
          "ERROR: Two-factor authentication failed"
        ) {
          this.step++;
          // Restart
          if (this.step > 2) {
            this.step = 1;
            // Focus on the username field (Need to wait for the field to render)
            setTimeout(() => this.$refs.username.focus());
            this.code = "";
            this.alert = true;
          } else {
            // Move to the next step (2FA)
            // Focus on the 2fa code field (Need to wait for the field to render)
            setTimeout(() => this.$refs.code.focus());
          }
        } else {
          this.alert = true;
        }
      } finally {
        this.loading = false;
      }
    }
  },
  created() {
    // Focus on the username field (Need to wait for the field to render)
    setTimeout(() => this.$refs.username.focus());
  }
};
</script>

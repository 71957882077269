<template>
  <div id="test-log">
    <v-toolbar flat class="title">
      <div class="text-no-wrap">Test Cases Log</div>
      <v-chip
        :color="selectedItems.length > 0 ? 'success' : ''"
        class="body-1 mx-4"
      >
        {{ selectedItems.length }}
      </v-chip>
      <v-divider vertical></v-divider>
      <v-subheader>
        <span v-if="assignment.readOnly">Selected test cases</span>
        <span v-else>Select your test cases</span>
      </v-subheader>
    </v-toolbar>
    <v-data-table
      id="test-log-table"
      :value="selectedItems"
      @input="v => $emit('updateSelected', v)"
      :show-select="items && items.length > 0"
      :headers="headers"
      :items="entries"
      :items-per-page="-1"
      item-key="runId"
      disable-sort
      fixed-header
      hide-default-footer
    >
      <template v-slot:no-data>
        <v-alert class="my-2 white--text" type="error">
          Looks like your test cases log is empty. Run some tests to fill it up!
        </v-alert>
      </template>
      <template
        v-if="blockUserInteraction"
        v-slot:[`header.data-table-select`]="{ props }"
      >
        <v-simple-checkbox
          :value="props.value"
          :indeterminate="props.indeterminate"
          disabled
        />
      </template>

      <template
        v-if="blockUserInteraction"
        v-slot:[`item.data-table-select`]="{ isSelected }"
      >
        <v-simple-checkbox :value="isSelected" disabled />
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          :disabled="blockUserInteraction"
          @click="deleteRunLog(item)"
          icon
        >
          <v-icon color="red">mdi-delete</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.testingTechnique`]="{ item }">
        <v-select
          :items="testingTechniques"
          :value="item.testingTechnique"
          :disabled="blockUserInteraction"
          :clearable="!selectedItems.some(log => log.runId === item.runId)"
          @change="
            value => $emit('modifyLogItem', item.id, 'testingTechnique', value)
          "
        ></v-select>
      </template>
      <template v-slot:[`item.expectedOutput`]="{ item }">
        <div
          class="d-flex flex-nowrap text-no-wrap align-center"
          style="cursor: pointer; font-size: 12px"
          @click="openExpectedOutput(item.id, item.expectedOutput)"
        >
          {{
            `${
              item.expectedOutput
                ? item.expectedOutput.length > 60
                  ? item.expectedOutput.slice(0, 60) + "..."
                  : item.expectedOutput
                : "-"
            }`
          }}
        </div>
      </template>
      <template v-slot:[`item.output`]="{ item }">
        <div class="d-flex flex-nowrap text-no-wrap align-center">
          <v-btn
            icon
            :loading="!item.consoleId"
            @click="openConsole(item.consoleId, item.error)"
          >
            <v-icon v-if="!item.error">mdi-console</v-icon>
            <v-icon v-else color="error">mdi-close</v-icon>
          </v-btn>
          <div
            v-if="cachedOutput[item.consoleId]"
            @click="openConsole(item.consoleId, item.error)"
            style="cursor: pointer; font-size: 12px"
          >
            {{
              `${
                cachedOutput[item.consoleId].length > 60
                  ? cachedOutput[item.consoleId].slice(0, 60) + "..."
                  : cachedOutput[item.consoleId]
              }`
            }}
          </div>
        </div>
      </template>
      <template v-slot:[`item.testCasePassed`]="{ item }">
        <v-select
          :items="testCaseResults"
          :value="item.testCasePassed"
          :disabled="blockUserInteraction"
          :clearable="!selectedItems.some(log => log.runId === item.runId)"
          @change="
            value => $emit('modifyLogItem', item.id, 'testCasePassed', value)
          "
        ></v-select>
      </template>
      <template
        v-for="parameterHeader of parameterHeaders"
        v-slot:[`item.${parameterHeader.text}`]="{ item }"
      >
        <div :key="parameterHeader.text">
          <div
            v-if="
              item[parameterHeader.text] &&
              item[parameterHeader.text].length > 20
            "
            class="font-weight-medium"
            style="cursor: pointer"
            @click="
              openFullParameterText(
                parameterHeader.text,
                item[parameterHeader.text]
              )
            "
          >
            {{ item[parameterHeader.text].slice(0, 20) }}...
          </div>

          <div v-else>{{ item[parameterHeader.text] }}</div>
        </div>
      </template>
    </v-data-table>
    <custom-dialog
      v-model="showExpectedOutputDialog"
      :readOnly="blockUserInteraction"
      :rows="10"
      title="Expected Output"
      :initialText="currentItemExpectedOutput"
      :canSave="true"
      @save="
        value => $emit('modifyLogItem', currentItemId, 'expectedOutput', value)
      "
      @close="showExpectedOutputDialog = false"
    />
    <custom-dialog
      v-model="showFullParameterText"
      :readOnly="true"
      :rows="10"
      :title="parameterHeader"
      :initialText="fullParameterText"
      @close="showFullParameterText = false"
    />
    <v-dialog v-model="consoleLog" max-width="700px">
      <v-card
        :loading="timeouts.length > 0 ? 'white' : false"
        dark
        color="black"
      >
        <v-card-title>
          {{ timeouts.length > 0 ? "Loading" : "Console" }}
          <v-spacer></v-spacer>
          <v-progress-circular
            v-if="summedTimeout"
            :value="perc"
            rotate="90"
            size="32"
          >
            <span class="caption">{{ parseInt(perc) }}</span>
          </v-progress-circular>
          <v-btn v-if="showTryAgainButton" icon @click="tryAgainClicked()">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon @click="closeConsole">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container px-0>
            <pre>{{
              cachedOutput[consoleID] === null ||
              cachedOutput[consoleID] === undefined
                ? "Please wait..."
                : cachedOutput[consoleID]
            }}</pre>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import Assignment from "@/services/AssignmentService";
import CustomDialog from "../core/CustomDialog.vue";

export default {
  components: { CustomDialog },
  props: {
    assignment: Object,
    components: Array,
    headers: Array,
    items: Array,
    selectedItems: Array,
    color: Object,
    assignmentRunCounter: Number
  },
  data: () => ({
    cachedOutput: {},
    consoleID: "",
    consoleLog: false,
    fibonacci: [1000, 1000, 2000, 3000, 5000],
    fibonacciTimeouts: [],
    showTryAgainButton: false,
    summedTimeout: 0,
    timeouts: [],
    rowTimeout: {},
    parameterHeader: "",
    fullParameterText: "",
    showFullParameterText: false,
    showExpectedOutputDialog: false,
    testingTechniques: ["BVA", "EP", "Other"],
    testCaseResults: [
      { text: "Pass", value: true },
      { text: "Fail", value: false }
    ],
    currentItemExpectedOutput: "",
    currentItemId: null
  }),
  computed: {
    blockUserInteraction() {
      return this.assignmentRunCounter > 0 || this.assignment.readOnly;
    },
    perc() {
      return (
        (this.summedTimeout * 100) /
        [...this.fibonacci].reduce((a, b) => a + b, 0)
      );
    },
    entries() {
      const items = [...this.items];
      for (let row_i = 0; row_i < items.length; row_i++) {
        // Transform only parameters cells
        for (let col_i = 5; col_i < this.headers.length - 1; col_i++) {
          const l = this.headers[col_i + 1].value;
          if (items[row_i][l] === undefined) items[row_i][l] = "NULL";
          if (items[row_i][l] === null || items[row_i][l] === "")
            items[row_i][l] = "EMPTY";
        }
      }
      this.enableItalicFont();
      return items;
    },
    parameterHeaders() {
      return this.headers.filter(header =>
        header.text.toLowerCase().includes("parameter")
      );
    }
  },
  methods: {
    openExpectedOutput(id, text) {
      this.showExpectedOutputDialog = true;
      this.currentItemExpectedOutput = text;
      this.currentItemId = id;
    },
    openFullParameterText(header, text) {
      this.parameterHeader = header;
      this.fullParameterText = text;
      this.showFullParameterText = true;
    },
    tryAgainClicked() {
      this.restoreFibonacci();
      this.getConsoleOutput(this.consoleID);
    },
    restoreFibonacci() {
      this.showTryAgainButton = false;
      this.fibonacciTimeouts = [...this.fibonacci];
    },
    restoreTimeouts() {
      this.timeouts.forEach(x => clearTimeout(x));
      this.timeouts = [];
      this.summedTimeout = 0;
    },
    async deleteRunLog(item) {
      if (!confirm("Are you sure you want to delete this row ?")) return;
      const runLogID = item.runId;
      let text = "";
      let color = "";
      try {
        await Assignment.deleteRunLog(this.assignment.id, runLogID);
        text = "Test log deleted.";
        color = "success";
        this.$emit("deleteLog", this.assignment.id, runLogID);
      } catch (err) {
        text =
          err?.response?.data?.message ||
          "Test log could not be deleted. Please try again later.";
        color = "error";
      } finally {
        this.$store.commit("alert", {
          text,
          color,
          timeout: 8000
        });
      }
    },
    async getConsoleOutput(console_id, output = "", running = true) {
      if (output === "" && running) {
        try {
          const res = await Assignment.getConsole(
            this.assignment.id,
            console_id
          );
          running = res.data.running;
          output = res.data.error
            ? res.data.error
            : res.data.log.toString().trim();
          if (this.fibonacciTimeouts.length > 0) {
            const curr_timeout = this.fibonacciTimeouts.shift();
            this.timeouts.push(
              setTimeout(() => {
                this.summedTimeout += curr_timeout;
                this.getConsoleOutput(console_id, output, running);
              }, curr_timeout)
            );
          } else {
            this.showTryAgainButton = true;
            this.cachedOutput[console_id] =
              "The executable is still running with the provided test case. Click on the refresh button in the top right corner to check again if the executable has exited successfuly and output is present.";
            this.restoreTimeouts();
          }
        } catch {
          this.restoreTimeouts();
          return (this.cachedOutput[console_id] =
            "An error occured. Please refresh the page and try again.");
        }
      } else {
        this.restoreTimeouts();
        return (this.cachedOutput[console_id] = output);
      }
    },
    openConsole(console_id, error) {
      // Save the console_id
      this.consoleID = console_id;
      // Open the console
      this.restoreFibonacci();
      this.consoleLog = true;
      if (console_id) {
        // Return the promise
        return this.getConsoleOutput(console_id);
      }
      if (error) {
        // Update the output with the error message
        this.cachedOutput[console_id] = error;
        // Return failed promise
        return -1;
      }
    },
    closeConsole() {
      this.consoleLog = false;
    },
    getDOMRows() {
      const table = document.getElementById("test-log-table");
      return table.getElementsByTagName("tr");
    },
    enableItalicFont() {
      this.$nextTick(() => {
        const rows = this.getDOMRows();
        for (const row of rows) {
          const cells = row.getElementsByTagName("td");
          for (let col = 2; col < cells.length; col++) {
            try {
              const val = cells[col].childNodes[0].childNodes[0].innerHTML;
              // TODO: What if input parameter is EMPTY or NULL ?
              if (val === "EMPTY" || val === "NULL")
                cells[col].classList.add("font-italic");
            } catch {
              continue;
            }
          }
        }
      });
    },
    cleanColorTimeout(id, row) {
      // Clean color, animation and clear timeout
      const { classList } = row;
      if (this.rowTimeout[id]) {
        this.$vuetify.theme.dark
          ? classList.remove("fade-dark")
          : classList.remove("fade-light");
        classList.remove(this.rowTimeout[id].color);
        clearTimeout(this.rowTimeout[id].timeout);
        delete this.rowTimeout[id];
      }
    },
    setColorTimeout(row, color, seconds) {
      const { classList } = row;
      const dark = this.$vuetify.theme.dark;
      return setTimeout(() => {
        dark ? classList.remove("fade-dark") : classList.remove("fade-light");
        classList.remove(color);
        classList.remove("test-log-run-selection");
      }, seconds * 1000);
    },
    setColor(row, color) {
      // Show row selection
      const { classList } = row;
      this.$vuetify.theme.dark
        ? classList.add("fade-dark")
        : classList.add("fade-light");
      classList.add(color);
      classList.add("test-log-run-selection");
    },
    showRowSelection(item) {
      const { id, color } = item;
      if (!id) return;
      const rows = this.getDOMRows();
      const row = rows[id];
      // Restore
      this.cleanColorTimeout(id, row);
      // Set new color
      this.setColor(row, color);
      // Set timeout for 10 seconds
      const timeout = this.setColorTimeout(row, color, 10);
      // Store timeout and color
      this.rowTimeout[id] = {
        color,
        timeout
      };
    }
  },
  beforeDestroy() {
    // Clear all timeouts
    Object.keys(this.rowTimeout).forEach(key => {
      clearTimeout(this.rowTimeout[key].timeout);
      delete this.rowTimeout[key];
    });
  },
  watch: {
    color: {
      immediate: true,
      deep: true,
      handler(row) {
        if (row) {
          this.$nextTick(() => {
            this.showRowSelection(row);
          });
        }
      }
    }
  }
};
</script>
<style>
/* https://stackoverflow.com/questions/248011/how-do-i-wrap-text-in-a-pre-tag */
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}
.fade-light {
  -webkit-animation: light 10s 1; /* Safari 4+ */
  -moz-animation: light 10s 1; /* Fx 5+ */
  -o-animation: light 10s 1; /* Opera 12+ */
  animation: light 10s 1; /* IE 10+, Fx 29+ */
}
@keyframes light {
  100% {
    background-color: white;
  }
}
@-moz-keyframes light {
  100% {
    background-color: white;
  }
}
@-webkit-keyframes light {
  100% {
    background-color: white;
  }
}
@-o-keyframes light {
  100% {
    background-color: white;
  }
}
@-ms-keyframes light {
  100% {
    background-color: white;
  }
}

.fade-dark {
  -webkit-animation: dark 10s 1; /* Safari 4+ */
  -moz-animation: dark 10s 1; /* Fx 5+ */
  -o-animation: dark 10s 1; /* Opera 12+ */
  animation: dark 10s 1; /* IE 10+, Fx 29+ */
}
@keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-moz-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-webkit-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-o-keyframes dark {
  100% {
    background-color: #424242;
  }
}
@-ms-keyframes dark {
  100% {
    background-color: #424242;
  }
}
</style>

<template>
  <v-dialog
    :value="value"
    @keydown.esc="cancel()"
    persistent
    max-width="700"
    content-class="popup"
  >
    <v-card dark :color="color">
      <v-card-title class="headline">
        <div style="word-break: break-word">
          {{ text }}
        </div>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="type !== 'submit'">
          <v-btn @click.stop="cancel" text>Cancel</v-btn>
          <v-btn @click="confirm" text>Don't save</v-btn>
        </div>
        <div v-else>
          <v-btn @click="confirm" text>Submit</v-btn>
          <v-btn @click.stop="cancel" text>Cancel</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    text: String,
    type: String
  },
  methods: {
    confirm() {
      if (this.type !== "submit") {
        this.$store.commit("setUnsavedChangesToSubmission", false);
      }
      this.$emit("input", false);
      this.$emit("confirm", this.type);
    },
    cancel() {
      this.$emit("input", false);
    }
  }
};
</script>

<style>
.v-dialog.popup {
  border-radius: 14px;
}
</style>

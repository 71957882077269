import axiosInstance from '@/services/AxiosInstance';

export default {
    getUsers() {
      return axiosInstance({
        method: 'get',
        url: 'user/',
      });
    },
    getUsersInActiveCommunity() {
      return axiosInstance({
        method: 'get',
        url: 'user/activecommunity/',
      });
    },
  };
  
<template>
  <v-container v-if="loggedUser.roleID.length > 1" class="mt-2">
    <v-row v-for="role of loggedUser.roleID" :key="role._id">
    <v-chip
      @click="updateActiveRole(role)"
      large
      class="mt-2"
    >
      <v-icon
        v-if="loggedUser.roleIDActive === role._id"
        large
        color="success"
        class="mr-2"
      >
        mdi-checkbox-marked-circle
      </v-icon>
      <v-icon large class="mr-2" v-else>
        mdi-checkbox-blank-circle-outline
      </v-icon>
      {{ role.name }} in {{ role.communityID.name }}
    </v-chip>
    </v-row>
  </v-container>
</template>
<script>
import PreferencesService from "@/services/PreferencesService";

export default {
  computed: {
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    } 
  },
  methods: {
    async updateActiveRole(role) {
      await PreferencesService.updateUserActiveProfile({
        roleIDActive: role._id
      });
      // TODO: Refresh data without reloading the page
      this.$router.push("/");
      this.$router.go();
    }
  }
};
</script>

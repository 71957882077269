var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"xs":"12","lg":"8","xl":"4"}},[_c('v-card',{attrs:{"id":"registerCard"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addUserWithLink.apply(null, arguments)}}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-img',{attrs:{"src":"/api/image/register/800/250","lazy-src":require('../assets/update_profile_pic.jpg'),"height":"15vh"}}):_vm._e(),_c('v-card-title',[_vm._v("Signup")]),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4","xl":"12"}},[_c('v-text-field',{attrs:{"label":"Username","autocomplete":"username","dense":"","rules":[
                      _vm.rules.required,
                      _vm.rules.min4Chars,
                      _vm.rules.max48Chars,
                      _vm.rules.noWhitespace
                    ]},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4","xl":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"new-password","label":"Password","dense":"","append-icon":_vm.hiddenModePassword ? 'visibility' : 'visibility_off',"type":_vm.hiddenModePassword ? 'password' : 'text',"rules":[
                      _vm.rules.required,
                      _vm.rules.min8Chars,
                      _vm.rules.max72Chars
                    ]},on:{"click:append":function($event){_vm.hiddenModePassword = !_vm.hiddenModePassword}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4","xl":"6"}},[_c('v-text-field',{attrs:{"autocomplete":"confirm-password","label":"Confirm password","dense":"","append-icon":_vm.hiddenModeConfirmPassword
                        ? 'visibility'
                        : 'visibility_off',"type":_vm.hiddenModeConfirmPassword ? 'password' : 'text',"rules":[
                      _vm.rules.required,
                      _vm.rules.min8Chars,
                      _vm.rules.max72Chars
                    ]},on:{"click:append":function($event){_vm.hiddenModeConfirmPassword = !_vm.hiddenModeConfirmPassword}},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"12","md":"4","xl":"12"}},[_c('v-text-field',{attrs:{"label":"Email","autocomplete":"email","dense":"","rules":[_vm.rules.required, _vm.rules.validEmail]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"4","xl":"6"}},[_c('v-text-field',{attrs:{"label":"First name","autocomplete":"given-name","dense":"","rules":[
                      _vm.rules.required,
                      _vm.rules.max48Chars,
                      _vm.rules.alphaSpace
                    ]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}})],1),_c('v-col',{staticClass:"py-1",attrs:{"cols":"6","md":"4","xl":"6"}},[_c('v-text-field',{attrs:{"label":"Last name","autocomplete":"family-name","dense":"","rules":[
                      _vm.rules.required,
                      _vm.rules.max48Chars,
                      _vm.rules.alphaSpace
                    ]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1),(!_vm.$route.query.refLink)?_c('v-col',{staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Invitation code or link","dense":"","rules":[_vm.rules.validUUID]},model:{value:(_vm.user.link),callback:function ($$v) {_vm.$set(_vm.user, "link", $$v)},expression:"user.link"}})],1):_vm._e(),_c('v-col',{staticClass:"pt-0"},[_c('v-switch',{attrs:{"color":"pink"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" I agree to receive a survey by email to help improve DBugIT. ")])]},proxy:true}]),model:{value:(_vm.user.useEmailForResearch),callback:function ($$v) {_vm.$set(_vm.user, "useEmailForResearch", $$v)},expression:"user.useEmailForResearch"}})],1)],1)],1)],1),_c('v-btn',{attrs:{"block":"","x-large":"","dark":""},on:{"click":_vm.addUserWithLink}},[_vm._v("Signup")])],1)],1),_c('v-alert',{attrs:{"transition":"slide-y-transition","type":"error","dismissible":""},model:{value:(_vm.alert),callback:function ($$v) {_vm.alert=$$v},expression:"alert"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
export default {
    alert(state, payload) {
        if (payload == null) return
        state.alert.color = payload.color;
        state.alert.text = payload.text;
        state.alert.button = payload.button;
        state.alert.show = true;
        // Set individual timer
        clearTimeout(state.alert.timeout);
        state.alert.timeout = setTimeout(() => {
            state.alert.show = false;
        }, payload.timeout);
    },
    setIsUserLoggedin(state, payload) {
        state.isUserLoggedin = payload;
    },
    setAssignmentTree(state, payload) {
        state.tree[0].children = payload;
    },
    setUserAssignmentTree(state, payload) {
        // Connect students tree to the correct node
        function getNodeById(id, node) {
            const reduce = [].reduce;
            function runner(result, node) {
              if (result || !node) return result;
              if (node.id === id) return node;
              return (
                runner(null, node.children) ||
                reduce.call(Object(node), runner, result)
              );
            }
            return runner(null, node);
        }
        const folder = getNodeById(payload.id, state.tree);
        folder.children = payload.children;
    },
    setLoggedUser(state, payload) {
        Object.assign(state.loggedUser, payload)
    },
    setUnsavedChangesToSubmission(state, payload) {
        state.unsavedChangesToSubmission = payload;
    },
    setRole(state, payload) {
        state.loggedUser = {
            ...state.loggedUser,
            roleIDActive: payload._id
        }
    },
}

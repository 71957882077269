import axiosInstance from '@/services/AxiosInstance';

export default {
  sendCredentials(data) {
    return axiosInstance({
      method: 'post',
      url: 'user/login/',
      data
    });
  },
  isLoggedin() {
    return axiosInstance({
      method: 'get',
      url: 'user/login/',
    });
  },
}
<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col :cols="12" :md="6">
          <v-toolbar dense>
            <v-toolbar-title>Users in active community</v-toolbar-title>
          </v-toolbar>
          <v-data-table
            v-model="selectedUsers"
            :headers="headersUsers"
            :loading="loadingUsers"
            :items="users"
            item-key="username"
            show-select
            class="mt-1 elevation-3"
            height="75vh"
            :items-per-page="-1"
            fixed-header
            hide-default-footer
          ></v-data-table>
        </v-col>
        <v-col :cols="12" :md="6">
          <v-toolbar dense>
            <v-toolbar-title>Assignments in active community</v-toolbar-title>
          </v-toolbar>
          <v-data-table
            v-model="selectedAssignments"
            :headers="headersAssignments"
            :loading="loadingAssignments"
            :items="assignments"
            item-key="prettyName"
            show-select
            class="mt-1 elevation-3"
            height="75vh"
            :items-per-page="-1"
            fixed-header
            hide-default-footer
          >
            <template v-slot:[`item.blackbox`]="{ item }">
              <v-icon v-if="item.blackbox">mdi-check</v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-2">
        <v-col lg="6">
          <v-date-picker
            class="elevation-0"
            v-model="homeworkEnabledFromDate"
            :allowed-dates="isAllowedDateFrom"
          ></v-date-picker>
          <v-time-picker
            class="elevation-0 ml-5"
            format="24hr"
            v-model="homeworkEnabledFromTime"
          ></v-time-picker>
          <v-text-field label="From" disabled :value="homeworkEnabledFrom">
            From
          </v-text-field>
        </v-col>

        <v-col lg="6" justify>
          <v-date-picker
            class="elevation-0"
            v-model="homeworkEnabledToDate"
            :allowed-dates="isAllowedDateTo"
          ></v-date-picker>
          <v-time-picker
            class="elevation-0 ml-5"
            format="24hr"
            v-model="homeworkEnabledToTime"
          ></v-time-picker>
          <v-text-field label="To" disabled :value="homeworkEnabledTo">
            To
          </v-text-field>
        </v-col>
      </v-row>
      <v-row class="mt-2" justify="center">
        <v-col :cols="12" :sm="4" :md="3">
          <v-text-field
            disabled
            :value="selectedUsers.length"
            label="Users selected"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4" :md="2">
          <v-text-field
            disabled
            label="Blacbox assignments selected"
            :value="nrSelectedBlackbox"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="4" :md="2">
          <v-text-field
            disabled
            :value="nrSelectedWhitebox"
            label="Whitebox assignments selected"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="6" :md="2">
          <v-text-field
            disabled
            :value="blackboxPerUser"
            label="Blacbox assignments per user"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="6" :md="3">
          <v-text-field
            disabled
            :value="whiteboxPerUser"
            label="Whitebox assignments per user"
          ></v-text-field>
        </v-col>
        <v-col :cols="12" :sm="6" :md="2">
          <v-select
            :items="meantForItems"
            v-model="meantFor"
            return-object
            label="Meant for"
          ></v-select>
        </v-col>
        <v-col :cols="12" :sm="6" :md="2">
          <v-switch
            v-model="applyRandomDistribution"
            label="Use random distribution"
          ></v-switch>
        </v-col>
        <v-col :cols="12" :md="2">
          <v-btn
            @click="distributeAssignments()"
            :loading="loadingDistribution"
            block
            class="mt-3"
            color="blue"
          >
            <span v-if="applyRandomDistribution">Distribute randomly</span>
            <span v-else>Set access</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// Services
import Assignment from "@/services/AssignmentService";
import User from "@/services/UserService";
import AssignmentAccess from "@/services/AssignmentAccessService";

export default {
  data: () => ({
    applyRandomDistribution: true,
    meantForItems: [
      { text: "Bug Hunt", value: "BugHunt" },
      { text: "Bug Hunt Resit", value: "Resit" },
      { text: "Testing current access", value: "TestCurrent" },
      { text: "Testing future access", value: "TestFuture" },
      { text: "No Meant for label", value: undefined }
    ],
    meantFor: { text: "Bug Hunt", value: "BugHunt" },
    homeworkEnabledFromTime: `${new Date().getHours()}:${new Date().getMinutes()}`,
    homeworkEnabledFromDate: new Date().toISOString().substr(0, 10),
    homeworkEnabledFrom: new Date(),
    homeworkEnabledToTime: `${new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    ).getHours()}:${new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    ).getMinutes()}`,
    homeworkEnabledToDate: new Date(
      new Date().getTime() + 7 * 24 * 60 * 60 * 1000
    )
      .toISOString()
      .substr(0, 10),
    homeworkEnabledTo: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
    selectedUsers: [],
    selectedAssignments: [],
    headersUsers: [
      { text: "Username", value: "username" },
      { text: "Full name", value: "fullName" },
      { text: "Email", value: "email" }
    ],
    headersAssignments: [
      { text: "Name", value: "prettyName", sortable: true },
      { text: "Blackbox", value: "blackbox", sortable: true }
    ],
    loadingDistribution: false,
    users: [],
    loadingUsers: false,
    assignments: [],
    loadingAssignments: false
  }),
  methods: {
    async distributeAssignments() {
      const parameters = {
        users: this.selectedUsers,
        assignments: this.selectedAssignments,
        meantFor: this.meantFor.value,
        applyRandomDistribution: this.applyRandomDistribution,
        from: this.homeworkEnabledFrom,
        to: this.homeworkEnabledTo
      };
      this.loadingDistribution = true;
      try {
        const res = await AssignmentAccess.postAssignmentDistributionParameters(
          parameters
        );
        this.$store.commit("alert", {
          text: res.data.message,
          color: "success",
          timeout: 10000
        });
      } catch (err) {
        this.$store.commit("alert", {
          text: err.response.data.message,
          color: "error",
          timeout: 10000
        });
      } finally {
        this.loadingDistribution = false;
      }
    },
    async getUsers() {
      this.loadingUsers = true;
      try {
        const res = await User.getUsersInActiveCommunity();
        this.users = res.data.data.map(user => ({
          id: user._id,
          username: user.username,
          fullName: `${user.firstName} ${user.lastName}`,
          email: user.email
        }));
      } catch (err) {
        this.$store.commit("alert", {
          text: "Unable to get users",
          color: "error",
          timeout: 10000
        });
      } finally {
        this.loadingUsers = false;
      }
    },
    async getAssignments() {
      this.loadingAssignments = true;
      try {
        const res = await Assignment.getAssignmentsFromActiveCommunity();
        this.assignments = res.data.map(assignment => ({
          id: assignment._id,
          prettyName: assignment.prettyName,
          blackbox: assignment.blackbox,
          name: assignment.name,
          bugType: assignment.bugType
        }));
      } catch (err) {
        this.$store.commit("alert", {
          text: "Unable to get assignments",
          color: "error",
          timeout: 10000
        });
      } finally {
        this.loadingAssignments = false;
      }
    },
    isAllowedDateFrom(val) {
      const currentDate = new Date().toISOString().substr(0, 10);
      return val >= currentDate;
    },
    isAllowedDateTo(val) {
      return val >= this.homeworkEnabledFromDate;
    }
  },
  computed: {
    blackboxPerUser() {
      if (this.applyRandomDistribution) {
        return 1;
      } else {
        return this.nrSelectedBlackbox;
      }
    },
    whiteboxPerUser() {
      if (this.applyRandomDistribution) {
        return 1;
      } else {
        return this.nrSelectedWhitebox;
      }
    },
    nrSelectedBlackbox() {
      return this.selectedAssignments.filter(assignment => assignment.blackbox)
        .length;
    },
    nrSelectedWhitebox() {
      return this.selectedAssignments.filter(assignment => !assignment.blackbox)
        .length;
    }
  },
  created() {
    this.getUsers();
    this.getAssignments();
  },
  watch: {
    homeworkEnabledFromTime(val) {
      // if the from and to dates are equal,check if times need to be adjusted
      if (this.homeworkEnabledFromDate == this.homeworkEnabledToDate) {
        if (val > this.homeworkEnabledToTime) {
          this.homeworkEnabledToTime = val;
        }
      }
      // update homeworkEnabledFrom
      this.homeworkEnabledFrom = new Date(
        `${this.homeworkEnabledFromDate} ${val}`
      );
    },
    homeworkEnabledFromDate(val) {
      // if from is bigger than to, update to
      if (val > this.homeworkEnabledToDate) this.homeworkEnabledToDate = val;
      // if the dates are equal,check if times need to be adjusted
      if (
        val == this.homeworkEnabledToDate &&
        this.homeworkEnabledFromTime > this.homeworkEnabledToTime
      ) {
        this.homeworkEnabledFromTime = this.homeworkEnabledToTime;
      }
      // update homeworkEnabledFrom
      this.homeworkEnabledFrom = new Date(
        `${val} ${this.homeworkEnabledFromTime}`
      );
    },
    homeworkEnabledToTime(val) {
      // if dates are equal, check if times need to be adjusted
      if (this.homeworkEnabledFromDate == this.homeworkEnabledToDate) {
        if (val < this.homeworkEnabledFromTime) {
          this.homeworkEnabledFromTime = val;
        }
      }
      // update homeworkEnabledTo
      this.homeworkEnabledTo = new Date(`${this.homeworkEnabledToDate} ${val}`);
    },
    homeworkEnabledToDate(val) {
      // if dates are equal, check if times need to be adjusted
      if (
        val == this.homeworkEnabledFromDate &&
        this.homeworkEnabledFromTime > this.homeworkEnabledToTime
      ) {
        this.homeworkEnabledFromTime = this.homeworkEnabledToTime;
      }
      // update homeworkEnabledTo
      this.homeworkEnabledTo = new Date(`${val} ${this.homeworkEnabledToTime}`);
    }
  }
};
</script>

/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import Login from '@/views/Login'
import Preferences from "@/views/Preferences.vue";
import Welcome from '@/views/Welcome'
import Register from '@/views/Register'
import Main from '@/views/Main'
import store from '@/store';

import LoginService from '@/services/LoginService';

// import store from '@/store'
// Routes
function route(path, file, name, auth, children) {
  return {
    exact: true,
    path,
    name,
    children,
    component: file,
    meta: {
      requiresAuth: auth,
    },
  };
}

Vue.use(Router)
const pathName = store.getters.getRootName;
const homeRouteName =  "Main--".concat(pathName.substr(1));

const routes = [
  // Auth
  route('/login', Login, 'Login', false),
  route('/register', Register, 'Register', false),
  // Preferences
  route('/preferences', Preferences, 'Preferences', true),
  // Files and Folders
  route('/welcome', Welcome, 'Welcome', false),

  route(pathName, Main, homeRouteName, true, [
    route('*', null, pathName.substr(1), true),
  ]),
  { path: '/', redirect: pathName },
];

// Create a new router
const router = new Router({
  base: process.env.BASE_URL,
  mode: 'history',
  routes,
  scrollBehavior: () => ({ y: 0 }),
});

async function possibleRerouteToHome() {
  try {
    await LoginService.isLoggedin();
    return pathName;
  } catch (err) {
    console.log(err);
    return undefined;
  }
}
async function possibleRerouteToWelcome() {
  try {
    const res = await LoginService.isLoggedin();
    store.commit("setIsUserLoggedin", res.data.success);
    store.commit("setLoggedUser", res.data.user);
    return undefined;
  } catch (err) {
    console.log(err);
    return "/welcome";
  }
}
async function handleNonExistingRoutes() {
  try {
    await LoginService.isLoggedin();
    return pathName;
  } catch (err) {
    console.log(err);
    return "/welcome";
  }
}

async function handleRoutes(routerName) {
 let nextRoute = undefined;
  if (
    routerName === "Welcome" ||
    routerName === "Register" ||
    routerName === "Login"
  ) {
    nextRoute = await possibleRerouteToHome();
  } else if (routerName === "Preferences") {
    nextRoute = await possibleRerouteToWelcome();
  } else {
    nextRoute = await handleNonExistingRoutes();
  } 
  return nextRoute
}

router.beforeEach((to, from, next) => {
  if(store.getters.getUnsavedChangesToSubmission) {
    if(window.confirm("You have unsaved changes in your submission. Are you sure you want to leave this page?")) {
      store.commit("setUnsavedChangesToSubmission", false);
    } else {
      return next(false);
    } 
  }
  // if user wants to access an existing assignment folder or an assignment, the breadcrumbs and navigation components will take care of the route logic
  if(to.name === homeRouteName || to.name === pathName.substr(1)) {
    next();
  } else {
    handleRoutes(to.name).then(res => {
    if(res) {
      next({path: res});
    } else {
      next()
    }})
  }
})

export default router

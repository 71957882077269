<template>
  <div id="run-btn">
    <v-btn
      large
      color="success"
      rounded
      :disabled="loading"
      @click="run"
      class="pl-4 pr-0"
    >
      Run
      <v-btn :loading="loading" dark icon large class="ml-2" :ripple="false">
        <v-icon color="white">mdi-arrow-right-drop-circle</v-icon>
      </v-btn>
    </v-btn>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: false
  }),
  methods: {
    run() {
      if (this.loading) return;
      this.loading = true;
      this.$emit("run");
      setTimeout(
        () =>
          // Enable run
          (this.loading = false),
        1000
      );
    }
  }
};
</script>
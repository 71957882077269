import axiosInstance from '@/services/AxiosInstance';

export default {
  postAssignmentDistributionParameters(data) {
    return axiosInstance({
      method: 'post',
      url: 'assignmentaccess/distribute',
      data
    });
  },
};

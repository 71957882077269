<template>
  <div id="exercise-viewer" v-if="file">
    <v-overlay :value="assignmentLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container fluid class="py-1">
      <v-row>
        <v-col class="pt-0">
          <v-card class="exercise-card">
            <v-card-title class="title">
              Requirements specification
              <v-spacer></v-spacer>
              <v-btn icon @click="toggleRequirementsPanel">
                <v-icon>
                  {{ openRequirements ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text
              v-if="openRequirements"
              class="body-1"
              :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
            >
              <div v-if="assignment.requirements != ''">
                <vue-markdown :source="assignment.requirements"></vue-markdown>
              </div>
            </v-card-text>
            <div v-if="assignment.bugdescription">
              <v-divider />
              <v-card-title class="title">
                Bug Description
                <v-spacer></v-spacer>
                <v-btn icon @click="toggleBugDescriptionPanel">
                  <v-icon>
                    {{
                      openBugDescription ? "mdi-chevron-up" : "mdi-chevron-down"
                    }}
                  </v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text
                v-if="openBugDescription"
                class="body-1"
                :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
              >
                <span v-if="assignment.bugdescription">
                  {{ assignment.bugdescription }}
                </span>
                <v-progress-circular v-else indeterminate></v-progress-circular>
              </v-card-text>
            </div>
            <div v-if="assignment.code != null">
              <v-btn icon @click="toggleCodePanel" class="expandButton">
                <v-icon>
                  {{ openCode ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </v-btn>
              <ace-editor
                ref="codeEditor"
                height="1"
                v-model="assignment.code"
                @init="editorInit"
                :options="editorOptions"
                :lang="language"
                :theme="$vuetify.theme.dark ? 'chaos' : 'chrome'"
                style="border-radius: inherit"
              ></ace-editor>
            </div>
          </v-card>
        </v-col>
        <v-col
          v-if="
            $store.getters.getIsUserLoggedin && assignment.readOnly === false
          "
          class="pt-0"
        >
          <v-card class="exercise-card">
            <v-card-title>
              <span class="text-no-wrap">Number of test input parameters:</span>
              <v-spacer></v-spacer>
              <v-select
                v-model="numberOfParamsSelected"
                @change="loadInputParams"
                :items="selectParams"
                class="title"
              ></v-select>
            </v-card-title>
            <v-card-text>
              <inputs v-model="inputParams" @enter="$refs.runButton.run()" />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <run-btn ref="runButton" @run="run" />
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div v-if="$store.getters.getIsUserLoggedin">
        <log
          :components="inputParams"
          :headers="logHeaders"
          :items="logItems"
          :selectedItems="selectedLogItems"
          :color="logColor"
          :assignment="assignment"
          :assignmentRunCounter="assignmentRunCounter"
          @updateSelected="updateSelectedLogItems"
          @modifyLogItem="modifyLogItem"
          @deleteLog="getLogItems"
          class="my-2"
        />
        <v-sheet>
          <v-alert
            v-show="$store.getters.getUnsavedChangesToSubmission"
            class="my-5 white--text text-center"
            type="warning"
          >
            <strong>
              You need to submit in order to save your progress. Only the last
              submission will be graded.
            </strong>
          </v-alert>
          <v-toolbar flat height="80" class="title">
            <div class="text-no-wrap pr-5">Assumptions</div>
            <v-divider vertical></v-divider>
            <v-subheader style="line-height: 20px">
              List the assumptions you made that helped you generate the test
              cases and choose a test strategy.
            </v-subheader>
          </v-toolbar>
          <v-textarea
            clearable
            clear-icon="mdi-close-circle"
            class="ml-7"
            rows="4"
            placeholder="I assume that..."
            v-model="assumptions"
            :readonly="assignment.readOnly"
          ></v-textarea>
          <report
            v-model="report"
            :readOnly="assignment.readOnly"
            :reportLabel="reportLabel"
          />
          <v-toolbar flat height="80" class="title">
            <div class="text-no-wrap pr-5">Bug description</div>
            <v-divider vertical></v-divider>
            <v-subheader style="line-height: 20px">
              Describe the bug you found. Hint: you can use bug taxonomy.
            </v-subheader>
          </v-toolbar>
          <v-textarea
            clearable
            clear-icon="mdi-close-circle"
            class="ml-7"
            rows="2"
            placeholder="I found a bug in...The bug is of type..."
            v-model="bugDescription"
            :readonly="assignment.readOnly"
          ></v-textarea>
          <v-row justify="end" class="pa-7">
            <submit-btn
              v-if="!assignment.readOnly"
              @submit="submit"
              :error="err_msg"
            />
            <v-btn
              v-else
              :disabled="!assignment.lastsubmission"
              large
              rounded
              @click="generatePDF"
            >
              <v-icon class="pr-2">mdi-download</v-icon>
              Download PDF
            </v-btn>
          </v-row>
        </v-sheet>
      </div>
    </v-container>
  </div>
</template>

<script>
// Components
import Inputs from "../exercise/Inputs";
import RunBtn from "../exercise/RunButton";
import SubmitBtn from "../exercise/SubmitButton";
import Log from "../exercise/Log";
import Report from "../exercise/Report";
// Modules
import AceEditor from "vue2-ace-editor";
import VueMarkdown from "@adapttive/vue-markdown";
import * as Language from "@/modules/ace-editor-languages.mjs";

// Services
import Assignment from "@/services/AssignmentService";

export default {
  components: {
    AceEditor,
    VueMarkdown,
    Inputs,
    RunBtn,
    SubmitBtn,
    Log,
    Report
  },
  props: {
    file: String
  },
  data: () => ({
    assignmentLoading: false,
    numberOfParamsSelected: 0,
    maxNumberOfParams: 20,
    openRequirements: true,
    openBugDescription: true,
    openCode: true,
    assignment: {},
    inputParams: [],
    logHeaders: [],
    logItems: [],
    selectedLogItemsRunIds: [],
    logColor: {},
    assumptions: "",
    report: "",
    bugDescription: "",
    lastKnownAssumptions: "",
    lastKnownReport: "",
    lastKnownBugDescription: "",
    language: "py",
    editorOptions: {
      maxLines: Infinity,
      fontSize: 16,
      fontFamily: "monospace",
      highlightActiveLine: false,
      highlightGutterLine: false,
      showPrintMargin: false,
      readOnly: true
    },
    assignmentRunCounter: 0
  }),
  computed: {
    selectedLogItems() {
      return this.selectedLogItemsRunIds.map(runId =>
        this.logItems.find(item => item.runId === runId)
      );
    },
    lastKnownSubmission() {
      return `${this.lastKnownAssumptions}${this.lastKnownReport}${this.lastKnownBugDescription}`;
    },
    currentSubmission() {
      return `${this.assumptions}${this.report}${this.bugDescription}`;
    },
    reportLabel() {
      return "Explain your test strategy.";
    },
    err_msg() {
      if (this.selectedLogItemsRunIds.length === 0)
        return "Select the test cases you want to submit";
      else if (!this.assumptions) {
        return "Fill in assumptions field";
      } else if (!this.bugDescription) {
        return "Fill in bug description field";
      } else {
        // Strips html
        if (this.report.replace(/(<([^>]+)>)/gi, "").length === 0)
          return "Fill in test strategy field";
      }
      return "";
    },
    selectParams() {
      if (this.maxNumberOfParams > 0)
        return [...Array(this.maxNumberOfParams).keys()];
      return [];
    },
    currentTab() {
      return this.$store.state.tab;
    }
  },
  methods: {
    generatePDF() {
      window.open(this.assignment.pdf);
    },
    toggleCodePanel() {
      this.openCode = !this.openCode;
      this.$refs.codeEditor.editor.setOptions({
        maxLines: this.openCode ? Infinity : 20
      });
    },
    toggleRequirementsPanel() {
      this.openRequirements = !this.openRequirements;
    },
    toggleBugDescriptionPanel() {
      this.openBugDescription = !this.openBugDescription;
    },
    editorInit() {
      require("brace/ext/language_tools"); //language extension prerequsite...
      require("brace/theme/chrome"); // themes..
      require("brace/theme/chaos");
    },
    denyAccess() {
      this.$emit("notAuthorized");
      this.$store.commit("alert", {
        text: "Permission denied.",
        color: "error",
        timeout: 3000
      });
    },
    updateSelectedLogItems(selectedLogs) {
      this.selectedLogItemsRunIds = selectedLogs.map(log => log.runId);
    },
    async modifyLogItem(position, field, value) {
      const index = position - 1;
      const { runId } = this.logItems[index];
      try {
        this.$emit("assignmentLoading", true);
        await Assignment.patchRunLog(this.assignment.id, runId, {
          field,
          value
        });
        // make sure the Log component detects the change
        this.$set(this.logItems, index, {
          ...this.logItems[index],
          [field]: value
        });
      } catch (e) {
        const text =
          e?.response?.data?.message ||
          `An error occurred while trying to edit ${field}. Please try again later.`;
        this.$store.commit("alert", {
          text,
          color: "error",
          timeout: 10000
        });
      } finally {
        this.$emit("assignmentLoading", false);
      }
    },
    updateLogItems(data, position) {
      const index = position - 1;
      this.logItems[index].consoleId = data.console_id;
      this.logItems[index].runId = data.id;
      data.params.forEach(
        (p, i) => (this.logItems[index]["Parameter " + i] = p)
      );
    },
    async run() {
      const pos = this.createRow(this.inputParams);
      this.$emit("assignmentLoading", true);
      // Run with input params
      const run = { parameters: this.inputParams.map(({ value }) => value) };
      // Increase assignment run counter to keep track on number of currently running requests
      this.assignmentRunCounter++;
      // Send RUN API Request
      try {
        const res = await Assignment.runAssignment(this.file, run);
        if (res.data.id == -2) {
          this.denyAccess();
        } else {
          let row = this.logItems.find(x => x.runId == res.data.id);
          // If the runlog already exists
          if (row) {
            let line = row.id;
            // Remove the created row
            this.logItems.splice(pos - 1, 1);
            // Notify the user that the test exists
            this.logColor = { id: line, color: "warning" };
            this.$store.commit("alert", {
              text: "Test already exists on line: " + line,
              color: "warning",
              timeout: 8000,
              button: true
            });
          } else {
            // Update the log
            this.updateLogItems(res.data, pos);
            // Notify success
            this.logColor = { id: pos, color: "success" };
            this.$store.commit("alert", {
              text: "View Test Log to see your tests",
              color: "success",
              timeout: 8000,
              button: true
            });
          }
        }
      } catch (err) {
        // Set output to error state
        this.logItems[pos - 1].error = err;
      } finally {
        this.assignmentRunCounter--;
        this.$emit("assignmentLoading", false);
      }
    },
    loadInputParams(i) {
      let offset = i - this.inputParams.length;
      // align
      while (offset > 0) {
        this.inputParams.push({
          id: offset,
          name: "text-field",
          value: null
        });
        offset--;
      }
      while (offset < 0) {
        this.inputParams.pop();
        offset++;
      }
    },
    pushHeader(label, column) {
      column = column - 1 || this.logHeaders.length;
      // Check if the header exists
      if (!this.logHeaders.find(x => x.text === label))
        this.logHeaders.splice(column, 0, {
          text: label,
          value: label,
          align: "center"
        });
    },
    createRow(params, from_server = false) {
      // Headers is the set of all unique component names.
      // Items is each items value and is represented in every table row.
      const row = {};
      const len = params.length;
      let label;
      for (var i = 0; i < len; i++) {
        // Set label
        label = "Parameter " + i;
        // Get the value from the parameter inputs
        const { value } = params[i];
        // Update the value
        row[label] = from_server ? value : "...";
        // Push a new header if necessary
        this.pushHeader(label);
      }
      // Wait for output...
      row.id = this.logItems.length + 1;
      row.consoleId = false;
      // Returns the index
      return this.logItems.push(row);
    },
    async submit() {
      if (
        this.selectedLogItems.some(
          log =>
            !log?.testingTechnique ||
            !log?.expectedOutput ||
            log?.testCasePassed === undefined ||
            log?.testCasePassed === null
        )
      ) {
        const text =
          "Selected test cases must have testing technique, expected output and test case result filled in";
        this.$store.commit("alert", {
          text,
          color: "error",
          timeout: 8000
        });
        return;
      }
      const submission = {
        testCases: this.selectedLogItems.map(
          ({ runId, testingTechnique, expectedOutput, testCasePassed }) => ({
            runId,
            testingTechnique,
            expectedOutput,
            testCasePassed
          })
        ),
        assumptions: this.assumptions,
        testStrategy: this.report,
        bugDescription: this.bugDescription
      };
      this.$emit("assignmentLoading", true);
      try {
        const res = await Assignment.submitAssignment(this.file, submission);
        if (res.data.id == -2) {
          this.denyAccess();
        } else {
          this.$store.commit("setUnsavedChangesToSubmission", false);
          this.$store.commit("alert", {
            text:
              "Submission (" +
              res.data.submits +
              ") successful. " +
              "Note that only the last submission will be stored and graded.",
            color: "success",
            timeout: 10000
          });
        }
      } catch (e) {
        const text =
          e?.response?.data?.message ||
          "Submission failed. Please try again later.";
        this.$store.commit("alert", {
          text,
          color: "error",
          timeout: 10000
        });
      } finally {
        this.$emit("assignmentLoading", false);
      }
    },
    clearAssignment() {
      // Clean exercise
      this.assignment = {
        id: null,
        requirements: "",
        code: null,
        name: "",
        logs: []
      };
      this.openRequirements = true;
      this.openCode = true;
    },
    clearNumberOfParametersSelected() {
      // Clean params
      this.numberOfParamsSelected = 0;
      this.inputParams = [];
    },
    clearlogHeaders() {
      this.logHeaders = [
        {
          text: "#",
          value: "id",
          width: "20px",
          align: "start"
        },
        {
          text: "Actions",
          value: "actions",
          width: "20px",
          align: "start"
        },
        {
          text: "Testing Technique",
          value: "testingTechnique",
          width: "140px",
          align: "center"
        },
        {
          text: "Expected Output",
          value: "expectedOutput",
          width: "140px",
          align: "center"
        },
        {
          text: "Output",
          value: "output",
          width: "20px",
          align: "center"
        },
        {
          text: "Result",
          value: "testCasePassed",
          width: "140px",
          align: "center"
        }
      ];
    },
    clearlogItems() {
      this.logItems = [];
      this.logColor = {};
    },
    clearLogSelected() {
      this.selectedLogItemsRunIds = [];
    },
    clearUserText() {
      this.assumptions = "";
      this.lastKnownAssumptions = "";
      this.report = "";
      this.lastKnownReport = "";
      this.bugDescription = "";
      this.lastKnownBugDescription = "";
    },
    clearLog() {
      // Clean Test Log
      this.clearlogHeaders();
      this.clearlogItems();
    },
    cleanExercise() {
      this.clearAssignment();
      this.clearNumberOfParametersSelected();
      this.clearLog();
      this.clearLogSelected();
      this.clearUserText();
    },
    loadLogItems(logs = this.assignment.logs) {
      // Return if there are no logs
      if (!logs) return;
      this.clearLog();
      for (let log of logs) {
        const initialParams = [];
        for (let i = 0; i < log.params.length; i++) {
          initialParams.push({
            id: log.params.length - i + 1,
            name: "text-field",
            value: log.params[i]
          });
        }
        let pos = this.createRow(initialParams, true);
        this.logItems[pos - 1].consoleId = log.console_id;
        this.logItems[pos - 1].runId = log.id;
        this.logItems[pos - 1].testingTechnique = log.testingTechnique;
        this.logItems[pos - 1].expectedOutput = log.expectedOutput;
        this.logItems[pos - 1].testCasePassed = log.testCasePassed;
      }
    },
    loadLastSubmission(data) {
      if (Object.prototype.hasOwnProperty.call(data, "lastsubmission")) {
        for (const runId of data.lastsubmission.runids)
          this.selectedLogItemsRunIds.push(runId);
        this.assumptions = data.lastsubmission.assumptions;
        this.report = data.lastsubmission.testStrategy;
        this.bugDescription = data.lastsubmission.bugDescription;
        this.lastKnownAssumptions = this.assumptions;
        this.lastKnownReport = this.report;
        this.lastKnownBugDescription = this.bugDescription;
        // this.$refs.reportEditor.setMarkdown(this.report);
      }
    },
    loadNewData(data) {
      const { ext } = data;
      const commitData = () => {
        // Load assignment data
        this.assignment = Object.assign({}, data);
        // Load initial Test Log items
        this.loadLogItems();
        // Load last submission
        this.loadLastSubmission(data);
      };
      // If there is an extension load language highlight
      if (ext) {
        // Load language highlight
        return Language.activate(ext).then(() => {
          // ... and then
          commitData();
          // and set language
          this.language = Language.matched(ext);
        });
      } else {
        commitData();
      }
    },
    async getLogItems(assid, runid) {
      const res = await Assignment.getRunLogs(assid);
      this.selectedLogItemsRunIds = this.selectedLogItemsRunIds.filter(
        id => id !== runid
      );
      this.loadLogItems(res.data.logs);
    },
    async getAssignment(id) {
      this.cleanExercise();
      this.assignmentLoading = true;
      try {
        const res = await Assignment.getAssignmentInfo(id);
        if (res.data.id == -2) {
          this.denyAccess();
        } else {
          // Emit readOnly state
          this.$emit("readOnly", res.data.readOnly);
          // Load Data from server
          this.loadNewData(res.data);
        }
      } catch {
        this.$store.commit("alert", {
          text: "Unable to open the requested assignment.",
          color: "error",
          timeout: 0
        });
      } finally {
        this.assignmentLoading = false;
      }
    }
  },
  watch: {
    file(assid) {
      if (assid) {
        this.getAssignment(assid);
      }
    },
    currentSubmission(val) {
      if (val !== this.lastKnownSubmission && val !== "<p></p>") {
        this.$store.commit("setUnsavedChangesToSubmission", true);
      } else {
        this.$store.commit("setUnsavedChangesToSubmission", false);
      }
    }
  }
};
</script>
<style scoped>
.exercise-card {
  position: sticky;
  top: 70px;
  border-radius: 10px !important;
}
.expandButton {
  margin-top: 16px;
  right: 16px;
  position: absolute;
  z-index: 999;
}
</style>

<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container
      v-if="folderContent && folderContent.length > 0"
      fluid
      class="py-0"
    >
      <v-row>
        <v-col
          xl="2"
          lg="3"
          sm="4"
          cols="12"
          class="pa-1"
          v-for="(node, i) in folderContent"
          :key="i"
        >
          <v-tooltip bottom open-delay="500">
            <template v-slot:activator="{ on }">
              <v-hover v-slot:default="{ hover }">
                <v-card
                  v-on="on"
                  @click="$emit('goto', node)"
                  :elevation="hover ? ($vuetify.theme.dark ? 12 : 6) : 2"
                >
                  <v-card-title class="body-1">
                    <div class="headerClass">
                      <v-icon class="pr-2" size="42">
                        {{
                          Object.prototype.hasOwnProperty.call(node, "children")
                            ? "mdi-folder"
                            : "mdi-file"
                        }}
                      </v-icon>
                      {{ node.name }}
                    </div>
                  </v-card-title>
                </v-card>
              </v-hover>
            </template>
            <span>{{ node.name }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["pointer", "loading"],
  computed: {
    items() {
      return this.$store.getters.getAssignmentTree;
    },
    folderContent() {
      const node = this.getNodeById(this.pointer, this.items);
      return node?.children;
    }
  },
  methods: {
    getNodeById(id, node) {
      var reduce = [].reduce;
      function runner(result, node) {
        if (result || !node) return result;
        return (
          (node.id === id && node) ||
          runner(null, node.children) ||
          reduce.call(Object(node), runner, result)
        );
      }
      return runner(null, node);
    }
  }
};
</script>
<style>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
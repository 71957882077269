let importedLanguages = {
    py: {
        active: false,
        language: "python",
        icon: "mdi-language-python"
    },
    c: {
        active: false,
        language: "c_cpp",
        icon: "mdi-language-c"
    },
    cpp: {
        active: false,
        language: "c_cpp",
        icon: "mdi-language-cpp"
    },
    cs: {
        active: false,
        language: "csharp",
        icon: "mdi-language-csharp"
    },
    java: {
        active: false,
        language: "java"
    },
    html: {
        active: false,
        language: "html",
    },
    css: {
        active: false,
        language: "css"
    },
    js: {
        active: false,
        language: "javascript"
    },
    scala: {
        active: false,
        language: "scala"
    },
    php: {
        active: false,
        language: "php"
    },
    bat: {
        active: false,
        language: "batchfile"
    },
    sh: {
        active: false,
        language: "sh"
    },
    asm: {
        active: false,
        language: "assembly_x86"
    },
    sql: {
        active: false,
        language: "sql"
    }
}
export const activate = (ext) => {
    let currentLang = importedLanguages[ext]
    if (currentLang && !currentLang.active) {
        currentLang.active = true
        return import("brace/mode/" + currentLang.language)
    }
    return Promise.resolve()

}
export const matched = (ext) => {
    let currentLang = importedLanguages[ext]
    if (currentLang) return currentLang.language
}
<template>
  <v-container fluid px-0 py-0>
    <v-overlay :value="!imgLoaded">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-img
      style="height: calc(100vh - 44px)"
      src="../assets/digital-dreams-white-eased-out-compressed.png"
      v-on:load="imgLoaded = true"
    >
      <v-container
        v-if="imgLoaded"
        :class="$vuetify.breakpoint.smAndUp ? 'fill-height' : undefined"
        :fluid="$vuetify.breakpoint.smAndUp"
      >
        <v-row justify="end">
          <v-col cols="12" lg="5">
            <v-container fluid>
              <v-row class="fill-height px-0 py-0" justify="center">
                <v-col
                  cols="12"
                  :style="`margin-bottom: ${marginBottom[breakpoint]}px;`"
                >
                  <v-img
                    :height="imageSizes[breakpoint]"
                    contain
                    :src="require('../assets/logo_dbugit_dark.svg')"
                  />
                </v-col>
                <v-row justify="center">
                  <v-col cols="10" xl="7" class="pt-0">
                    <div
                      class="welcome-text"
                      :style="`font-size: ${textSizes[breakpoint]}px;`"
                    >
                      {{ welcomeText }}
                    </div>
                  </v-col>
                  <v-col cols="10" xl="8">
                    <div
                      class="welcome-text"
                      :style="`font-size: ${textSizes[breakpoint]}px;`"
                    >
                      {{ greetingFromAuthorsText }}
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-5" justify="center">
                  <v-col cols="4">
                    <v-btn
                      block
                      rounded
                      color="blue"
                      dark
                      x-large
                      @click="$router.push('/register')"
                    >
                      Sign up
                    </v-btn>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      block
                      rounded
                      color="blue"
                      dark
                      x-large
                      @click="$router.push('/login')"
                    >
                      Login
                    </v-btn>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    imgLoaded: false,
    welcomeText: `Welcome to the VU-DBugIT, a lively, interactive bug-hunting environment developed for 
    the course Software Testing at the Vrije Universiteit in Amsterdam. 
    VU-DBugIT is one of the products emerged from VU-BugZoo, an NRO Comenius Teaching 
    Fellow project. The tool is currently under construction, 
    but you can already have fun searching for the bugs we have hidden in various code artifacts.`,
    greetingFromAuthorsText: `Enjoy software testing in VU-DBugIT! The VU-DBugIT team - Marc Went, Glenn Visser, Robert Jansma, Viktor Bonev, Emil Apostolov, Martynas Rimkevicius and Natalia Silvis-Cividjian.`,
    imageSizes: { xs: "120", sm: "160", md: "200", lg: "200", xl: "350" },
    textSizes: { xs: "10", sm: "12", md: "14", lg: "12", xl: "16" },
    marginBottom: { xs: "-10", sm: "-20", md: "-20", lg: "-20", xl: "-40" }
  }),
  computed: {
    breakpoint() {
      return this.$vuetify.breakpoint.name;
    }
  }
};
</script>
<style scoped>
.welcome-text {
  text-align: center;
  color: white;
}
.fill-height {
  min-height: 100%;
  height: 100%;
}
</style>

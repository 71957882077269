<template>
  <v-app :dark="dark">
    <nav-viewer />
    <nav-footer />
    <alert />
  </v-app>
</template>
<script>
// Components
import NavViewer from "./components/core/Viewer";
import NavFooter from "@/components/navigation/Footer";
import Alert from "@/components/core/Alert";

export default {
  name: "App",
  components: {
    NavViewer,
    NavFooter,
    Alert
  },
  data() {
    return {
      //
    };
  },
  created() {
    this.setMode();
  },
  computed: {
    dark: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(val) {
        this.$vuetify.theme.dark = val;
      }
    }
  },
  methods: {
    setMode() {
      this.dark = localStorage.getItem("dark") === "true";
    }
  },
  watch: {
    dark(mode) {
      localStorage.setItem("dark", mode);
    }
  }
};
</script>


<style>
html {
  overflow-y: auto;
}
</style>

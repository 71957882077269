var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('pop-up',{attrs:{"color":"warning","text":_vm.popupText,"type":_vm.popupType},on:{"confirm":_vm.popupConfirmed},model:{value:(_vm.popup),callback:function ($$v) {_vm.popup=$$v},expression:"popup"}}),_c('v-navigation-drawer',{ref:"drawer",attrs:{"app":"","mini-variant":_vm.primaryDrawer.mini,"width":_vm.primaryDrawer.width,"temporary":""},model:{value:(_vm.primaryDrawer.model),callback:function ($$v) {_vm.$set(_vm.primaryDrawer, "model", $$v)},expression:"primaryDrawer.model"}},[_c('v-text-field',{staticClass:"ma-2",attrs:{"value":_vm.search,"loading":_vm.searchLoading,"append-icon":"mdi-magnify","spellcheck":"false","label":"Search","single-line":"","solo":"","hide-details":"","clearable":""},on:{"input":_vm.updateSearch}}),_c('v-treeview',{ref:"treeview",staticStyle:{"padding-bottom":"120px"},attrs:{"open":_vm.open,"active":_vm.active,"items":_vm.items,"search":_vm.searchTree,"dense":"","transition":"","open-on-click":"","return-object":""},on:{"update:open":function (arr) { return (_vm.open = arr); }},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [(Object.prototype.hasOwnProperty.call(item, 'children'))?_c('v-icon',[_vm._v(" "+_vm._s(open ? "mdi-folder-open" : "mdi-folder")+" ")]):_c('v-icon',[_vm._v("mdi-file")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"text-none",staticStyle:{"box-shadow":"none"},attrs:{"color":"transparent"},on:{"click":function($event){return _vm.nodeRequested(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}])})],1),_c('v-app-bar',{attrs:{"app":""}},[_c('v-progress-linear',{staticClass:"dbugit-loading",attrs:{"height":"5px","indeterminate":"","active":_vm.loading}}),_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.primaryDrawer.model = !_vm.primaryDrawer.model}}}),_c('v-img',{staticClass:"mt-2",attrs:{"height":"65","max-width":"75","contain":"","src":require(_vm.$vuetify.theme.dark
          ? '../../assets/logo_dbugit_dark.svg'
          : '../../assets/logo_dbugit_light.svg')}}),_c('v-spacer'),_c('settings-menu',{on:{"preferences":_vm.openPreferences,"logout":_vm.logoutRequested}})],1),_c('breadcrumbs',{on:{"goto":_vm.generateDrawer}}),(!_vm.file.id)?_c('explorer',{attrs:{"pointer":_vm.currentNode,"loading":_vm.explorerLoading},on:{"goto":_vm.selectNode}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <pop-up
      v-model="popup"
      color="warning"
      text="Freeze! You have unsaved changes in your submission! Note that switching to another assignment or folder without submitting first will result in losing your changes."
      type="breadcrumb"
      @confirm="pushRouteAndUpdateNav()"
    />
    <v-breadcrumbs :items="items" divider=">">
      <v-breadcrumbs-item
        @click="!item.disabled && breadcrumbClicked(item)"
        class="body-1"
        slot="item"
        slot-scope="{ item }"
        exact
      >
        <v-icon
          v-if="item.icon && $vuetify.breakpoint.mdAndDown"
          size="24"
          :class="{
            'breadcrumbs-item-disabled': item.disabled,
            'breadcrumbs-item-enabled': !item.disabled
          }"
        >
          {{ item.icon }}
        </v-icon>
        <span
          v-else
          class="breadcrumbs-item"
          :class="{
            'breadcrumbs-item-disabled': item.disabled,
            'breadcrumbs-item-enabled': !item.disabled
          }"
        >
          {{ item.text }}
        </span>
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </div>
</template>
<script>
import PopUp from "../core/PopUp";
export default {
  components: {
    PopUp
  },
  data: () => ({
    items: [],
    popup: false,
    currentItem: {}
  }),
  methods: {
    pushRouteAndUpdateNav() {
      this.$router.push(this.currentItem.to) && this.$emit("goto");
    },
    breadcrumbClicked(item) {
      this.currentItem = Object.assign({}, item);
      if (this.$store.getters.getUnsavedChangesToSubmission) {
        this.popup = true;
      } else {
        this.pushRouteAndUpdateNav();
      }
    },
    updateItems() {
      const { currentRoute } = this.$router;
      let breadcrumbs = [];
      let path = "";
      for (let item of currentRoute.path.split("/")) {
        if (item.length) {
          path += "/" + item;
          breadcrumbs.push({
            text: item.replace(/--/g, " "),
            disabled: path === currentRoute.path,
            to: path
          });
        }
      }
      if (breadcrumbs[0]) {
        breadcrumbs[0].icon = "mdi-home";
      }
      this.items = breadcrumbs;
    }
  },
  watch: {
    $route: {
      handler() {
        this.updateItems();
      },
      immediate: true
    }
  }
};
</script>
<style lang="scss">
.breadcrumbs-item {
  font-size: 90%;
}
.breadcrumbs-item-disabled {
  color: var(--v-primary-base);
}
.breadcrumbs-item-disabled:hover {
  cursor: default;
}
.breadcrumbs-item-enabled:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>

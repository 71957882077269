// https://vuex.vuejs.org/en/state.html

export default {
  status: 'AUTHENTICATED',
  loading: false,
  token: null,
  unsavedChangesToSubmission: false,
  notViewedLogs: {},
  loggedUser: {
    email: "",
    username: "",
    enabled: {},
    roleID: [],
    roleIDActive: "",
  },
  isUserLoggedin: null,
  tree: [
    {
      name: `Software Testing ${new Date().getFullYear()} Assignments`,
      id: "root",
      children: []
    }
  ],
  alert: {
    show: false,
    button: false,
    color: "",
    text: "",
    timeout: -1
  },
}

import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    theme: {
        themes: {
            light: {
                primary: colors.blue,
            },
            dark: {
                primary: colors.blue.lighten3,
            },
        },
        dark: false,
        options: {
            customProperties: true,
        },
    },
});

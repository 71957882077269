<template>
  <v-dialog :value="value" persistent max-width="700px">
    <v-card dark color="black">
      <v-card-title>
        {{ title }}
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="text" :readonly="readOnly" :rows="rows" />
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('close')">close</v-btn>
        <v-btn
          v-if="canSave"
          @click="
            $emit('save', text);
            $emit('close');
          "
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    readOnly: {
      type: Boolean,
      default: false
    },
    rows: Number,
    title: String,
    initialText: String,
    canSave: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      text: ""
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.text = this.initialText;
      }
    }
  }
};
</script>

<template>
  <div>
    <!-- Navigation -->
    <navigation
      :permissionDenied="permissionDenied"
      :loading="loading"
      :readOnly="readOnly"
      @restorePermission="() => (permissionDenied = false)"
      @filechange="id => (file = id)"
      @preferences="on => (preferences = on)"
    />
    <!-- Exercise viewer -->
    <exercise-viewer
      v-show="file"
      :file="file"
      @notAuthorized="() => (permissionDenied = true)"
      @assignmentLoading="isLoading => (loading = isLoading)"
      @readOnly="ro => (readOnly = ro)"
    />
  </div>
</template>

<script>
import Navigation from "../components/navigation/Navigation";
import ExerciseViewer from "../components/exercise/ExerciseViewer";

export default {
  components: {
    Navigation,
    ExerciseViewer
  },
  data: () => ({
    file: null,
    permissionDenied: false,
    loading: false,
    readOnly: false
  }),
  beforeMount() {
    window.addEventListener("beforeunload", this.checkForUnsavedChanges);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.checkForUnsavedChanges);
  },
  methods: {
    checkForUnsavedChanges(event) {
      if (this.$store.getters.getUnsavedChangesToSubmission) {
        event.preventDefault();
        event.returnValue = "";
      }
    }
  }
};
</script>
<style>
::-webkit-scrollbar {
  width: 0.6em;
  height: 0.6em;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 20px;
}
.noselect {
  pointer-events: none;
}
</style>

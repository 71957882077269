<template>
  <v-container v-if="value != null">
    <v-row>
      <keep-alive v-for="(item, i) in value" :key="item + i">
        <component
          v-bind:is="'v-' + item.name"
          v-model="item.value"
          :label="'Parameter ' + i.toString()"
          outlined
          persistent-hint
          clearable
          hide-details
          autocomplete="off"
          class="pa-2"
          @keydown.enter="$emit('enter')"
        >
          <!-- PROPS FOR OTHER INPUT TYPES -->
          <!-- :items="componentItems[i]"
          :multiple="item.name==='combobox'"
          small-chips-->
          <!-- VRadio not used -->
          <!-- <v-radio
            v-for="c in item.count"
            :label="i+'.'+c"
            :key="item+c"
          ></v-radio>-->
        </component>
      </keep-alive>
    </v-row>
  </v-container>
</template>

<script>
import {
  VTextField
  // VCombobox,
  // VCheckbox,
  // VRadioGroup,
  // VRadio,
  // VSwitch,
} from "vuetify/lib";

export default {
  // data: () => ({ componentItems: [] }),
  props: {
    value: Array
  },
  components: {
    VTextField
    // VCombobox,
    // VCheckbox,
    // VRadioGroup,
    // VRadio,
    // VSwitch,
  }
};
</script>

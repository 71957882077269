<template>
  <v-main>
    <v-container
      :fill-height="$router.currentRoute.path === '/login'"
      fluid
      pa-0
    >
      <router-view />
    </v-container>
  </v-main>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "DBugIT"
    };
  }
};
</script>


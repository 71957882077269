// The Vue build version to load with the `import` command
import Vue from 'vue'
// Vuetify
import vuetify from './plugins/vuetify' // path to vuetify export
// Report editor
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
// Application imports
import App from './App'
import router from '@/router'
import store from '@/store'
// Sync router with store
import { sync } from 'vuex-router-sync'
// Sync store with router
sync(store, router);
// Settings 
Vue.config.productionTip = false;
Vue.config.performance = true;
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
// Provide TipTap the Vuetify Object.
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  // optional, default to 'md'
  iconsGroup: 'mdi'
})

/* eslint-disable no-new */
new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"test-log"}},[_c('v-toolbar',{staticClass:"title",attrs:{"flat":""}},[_c('div',{staticClass:"text-no-wrap"},[_vm._v("Test Cases Log")]),_c('v-chip',{staticClass:"body-1 mx-4",attrs:{"color":_vm.selectedItems.length > 0 ? 'success' : ''}},[_vm._v(" "+_vm._s(_vm.selectedItems.length)+" ")]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-subheader',[(_vm.assignment.readOnly)?_c('span',[_vm._v("Selected test cases")]):_c('span',[_vm._v("Select your test cases")])])],1),_c('v-data-table',{attrs:{"id":"test-log-table","value":_vm.selectedItems,"show-select":_vm.items && _vm.items.length > 0,"headers":_vm.headers,"items":_vm.entries,"items-per-page":-1,"item-key":"runId","disable-sort":"","fixed-header":"","hide-default-footer":""},on:{"input":function (v) { return _vm.$emit('updateSelected', v); }},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"my-2 white--text",attrs:{"type":"error"}},[_vm._v(" Looks like your test cases log is empty. Run some tests to fill it up! ")])]},proxy:true},(_vm.blockUserInteraction)?{key:"header.data-table-select",fn:function(ref){
var props = ref.props;
return [_c('v-simple-checkbox',{attrs:{"value":props.value,"indeterminate":props.indeterminate,"disabled":""}})]}}:null,(_vm.blockUserInteraction)?{key:"item.data-table-select",fn:function(ref){
var isSelected = ref.isSelected;
return [_c('v-simple-checkbox',{attrs:{"value":isSelected,"disabled":""}})]}}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":_vm.blockUserInteraction,"icon":""},on:{"click":function($event){return _vm.deleteRunLog(item)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)]}},{key:"item.testingTechnique",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.testingTechniques,"value":item.testingTechnique,"disabled":_vm.blockUserInteraction,"clearable":!_vm.selectedItems.some(function (log) { return log.runId === item.runId; })},on:{"change":function (value) { return _vm.$emit('modifyLogItem', item.id, 'testingTechnique', value); }}})]}},{key:"item.expectedOutput",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap text-no-wrap align-center",staticStyle:{"cursor":"pointer","font-size":"12px"},on:{"click":function($event){return _vm.openExpectedOutput(item.id, item.expectedOutput)}}},[_vm._v(" "+_vm._s(("" + (item.expectedOutput ? item.expectedOutput.length > 60 ? item.expectedOutput.slice(0, 60) + "..." : item.expectedOutput : "-")))+" ")])]}},{key:"item.output",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-nowrap text-no-wrap align-center"},[_c('v-btn',{attrs:{"icon":"","loading":!item.consoleId},on:{"click":function($event){return _vm.openConsole(item.consoleId, item.error)}}},[(!item.error)?_c('v-icon',[_vm._v("mdi-console")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close")])],1),(_vm.cachedOutput[item.consoleId])?_c('div',{staticStyle:{"cursor":"pointer","font-size":"12px"},on:{"click":function($event){return _vm.openConsole(item.consoleId, item.error)}}},[_vm._v(" "+_vm._s(("" + (_vm.cachedOutput[item.consoleId].length > 60 ? _vm.cachedOutput[item.consoleId].slice(0, 60) + "..." : _vm.cachedOutput[item.consoleId])))+" ")]):_vm._e()],1)]}},{key:"item.testCasePassed",fn:function(ref){
var item = ref.item;
return [_c('v-select',{attrs:{"items":_vm.testCaseResults,"value":item.testCasePassed,"disabled":_vm.blockUserInteraction,"clearable":!_vm.selectedItems.some(function (log) { return log.runId === item.runId; })},on:{"change":function (value) { return _vm.$emit('modifyLogItem', item.id, 'testCasePassed', value); }}})]}},_vm._l((_vm.parameterHeaders),function(parameterHeader){return {key:("item." + (parameterHeader.text)),fn:function(ref){
var item = ref.item;
return [_c('div',{key:parameterHeader.text},[(
            item[parameterHeader.text] &&
            item[parameterHeader.text].length > 20
          )?_c('div',{staticClass:"font-weight-medium",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openFullParameterText(
              parameterHeader.text,
              item[parameterHeader.text]
            )}}},[_vm._v(" "+_vm._s(item[parameterHeader.text].slice(0, 20))+"... ")]):_c('div',[_vm._v(_vm._s(item[parameterHeader.text]))])])]}}})],null,true)}),_c('custom-dialog',{attrs:{"readOnly":_vm.blockUserInteraction,"rows":10,"title":"Expected Output","initialText":_vm.currentItemExpectedOutput,"canSave":true},on:{"save":function (value) { return _vm.$emit('modifyLogItem', _vm.currentItemId, 'expectedOutput', value); },"close":function($event){_vm.showExpectedOutputDialog = false}},model:{value:(_vm.showExpectedOutputDialog),callback:function ($$v) {_vm.showExpectedOutputDialog=$$v},expression:"showExpectedOutputDialog"}}),_c('custom-dialog',{attrs:{"readOnly":true,"rows":10,"title":_vm.parameterHeader,"initialText":_vm.fullParameterText},on:{"close":function($event){_vm.showFullParameterText = false}},model:{value:(_vm.showFullParameterText),callback:function ($$v) {_vm.showFullParameterText=$$v},expression:"showFullParameterText"}}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.consoleLog),callback:function ($$v) {_vm.consoleLog=$$v},expression:"consoleLog"}},[_c('v-card',{attrs:{"loading":_vm.timeouts.length > 0 ? 'white' : false,"dark":"","color":"black"}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.timeouts.length > 0 ? "Loading" : "Console")+" "),_c('v-spacer'),(_vm.summedTimeout)?_c('v-progress-circular',{attrs:{"value":_vm.perc,"rotate":"90","size":"32"}},[_c('span',{staticClass:"caption"},[_vm._v(_vm._s(parseInt(_vm.perc)))])]):_vm._e(),(_vm.showTryAgainButton)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.tryAgainClicked()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1):_vm._e(),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeConsole}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-container',{attrs:{"px-0":""}},[_c('pre',[_vm._v(_vm._s(_vm.cachedOutput[_vm.consoleID] === null ||
            _vm.cachedOutput[_vm.consoleID] === undefined
              ? "Please wait..."
              : _vm.cachedOutput[_vm.consoleID]))])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="preferences">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="isUserLoggedIn">
      <div
        v-if="
          currentRole === 'OWNER' ||
          currentRole === 'ADMIN' ||
          currentRole === 'MODERATOR'
        "
      >
        <v-tabs @change="v => (tab = v)" fixed-tabs>
          <v-tab
            v-for="resource in [
              'Community',
              'Roles',
              'Users',
              'Links',
              'User profile',
              'Distribute assignments'
            ]"
            :key="resource"
          >
            {{ resource }}
          </v-tab>
          <v-spacer></v-spacer>
          <v-btn @click="$router.push('/')" icon large class="mx-2">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-tab-item>
            <v-toolbar class="headline" flat>
              Communities
              <v-spacer></v-spacer>
              <v-text-field
                v-model="name"
                placeholder="Enter a new community name"
                rounded
                solo
                class="mx-3"
                hide-details
              ></v-text-field>
              <v-select
                v-model="repo"
                :items="['Free', 'Paid']"
                placeholder="Choose repo"
                rounded
                solo
                class="mx-3"
                hide-details
              ></v-select>
              <v-btn @click="addCommunity({ name, repo })" x-large rounded>
                Create
              </v-btn>
            </v-toolbar>
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="item in communities"
                  :key="item._id"
                  :cols="12"
                  :md="6"
                  :lg="4"
                  :xl="3"
                >
                  <v-card height="100%">
                    <v-card-title>
                      {{ item.name }}
                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="isCurrentCommunity(item)"
                        color="success"
                        size="40"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-btn
                        v-else
                        color="red"
                        large
                        icon
                        @click.stop="deleteCommunity(item._id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-text class="mb-7">
                      <v-chip
                        v-for="(role, i) in item.roleID"
                        :key="role._id"
                        :color="'red lighten-' + (2 * i - 1)"
                        class="mr-1 black--text"
                      >
                        {{ role.name }}
                      </v-chip>
                    </v-card-text>
                    <v-card-actions
                      v-if="item.enabled"
                      style="position: absolute; bottom: 0; right: 0"
                    >
                      <v-btn icon @click="activateClock(item)">
                        <v-icon>mdi-clock</v-icon>
                      </v-btn>
                      <div class="title mx-2">
                        {{ item.enabled.val ? "ON" : "OFF" }}
                      </div>
                      <v-simple-checkbox
                        :ripple="false"
                        :value="item.enabled.val"
                        color="success"
                      ></v-simple-checkbox>
                    </v-card-actions>
                  </v-card>
                </v-col>
                <v-col></v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar class="headline" flat>Roles</v-toolbar>
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="item in roles"
                  :key="item._id"
                  :cols="12"
                  :sm="6"
                  :md="3"
                >
                  <v-card height="100%">
                    <v-card-title class="mb-4">
                      {{ item.name }}
                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="isCurrentRole(item)"
                        color="success"
                        size="40"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-btn
                        v-else
                        color="red"
                        large
                        icon
                        @click.stop="deleteRole(item._id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        v-if="currentRole === 'OWNER'"
                        @click="openUpdateRoleMenu(item)"
                      >
                        <v-icon>mdi-pen</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle v-if="item.communityID">
                      {{ item.communityID.name }}
                    </v-card-subtitle>
                    <v-card-actions
                      v-if="item.enabled"
                      style="position: absolute; bottom: 0; right: 0"
                    >
                      <v-btn icon @click="activateClock(item)">
                        <v-icon>mdi-clock</v-icon>
                      </v-btn>
                      <div class="title mx-2">
                        {{ item.enabled.val ? "ON" : "OFF" }}
                      </div>
                      <v-simple-checkbox
                        :ripple="false"
                        :value="item.enabled.val"
                        color="success"
                      ></v-simple-checkbox>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-toolbar class="headline" flat>
              Users
              <v-spacer></v-spacer>
              <v-btn @click="register = true" rounded large>Create</v-btn>
            </v-toolbar>
            <v-container fluid>
              <v-row>
                <v-col
                  v-for="item in users"
                  :key="item._id"
                  :cols="12"
                  :sm="6"
                  :md="3"
                >
                  <v-card height="100%">
                    <v-card-title>
                      {{ item.firstName }} {{ item.lastName }}
                      <v-chip v-if="item.admin" class="ml-2 red darken-4">
                        <v-icon color="white">mdi-star</v-icon>
                      </v-chip>
                      <v-spacer></v-spacer>
                      <v-icon
                        v-if="isCurrentUser(item)"
                        color="success"
                        size="40"
                      >
                        mdi-checkbox-marked-circle
                      </v-icon>
                      <v-btn
                        v-else
                        color="red"
                        large
                        icon
                        @click="deleteUser(item._id)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        v-if="currentRole === 'OWNER'"
                        @click="openUpdateUserMenu(item)"
                      >
                        <v-icon>mdi-pen</v-icon>
                      </v-btn>
                    </v-card-title>
                    <v-card-subtitle>
                      {{ item.email }}
                    </v-card-subtitle>

                    <v-card-text>
                      <v-container>
                        <v-row
                          v-for="(role, i) in item.roleID"
                          :key="i"
                          class="my-2"
                        >
                          <div class="title">
                            {{ role.communityID.name }}
                          </div>
                          <v-divider class="mx-2" vertical />
                          <v-chip>
                            <v-icon
                              v-if="item.roleIDActive === role._id"
                              class="mr-2"
                              color="success"
                            >
                              mdi-checkbox-marked-circle
                            </v-icon>
                            {{ role.name }}
                          </v-chip>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions
                      v-if="item.enabled"
                      style="position: absolute; bottom: 0; right: 0"
                    >
                      <v-btn icon @click="activateClock(item)">
                        <v-icon>mdi-clock</v-icon>
                      </v-btn>
                      <div class="title mx-2">
                        {{ item.enabled.val ? "ON" : "OFF" }}
                      </div>
                      <v-simple-checkbox
                        :ripple="false"
                        :value="item.enabled.val"
                        color="success"
                      ></v-simple-checkbox>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container fluid>
              <v-row>
                <v-col lg="12">
                  <v-toolbar class="headline" flat>
                    Links
                    <v-spacer></v-spacer>
                    <v-select
                      v-if="roles"
                      v-model="link.roleID"
                      label="Choose a role"
                      :items="roles"
                      item-value="_id"
                      class="mx-5"
                      style="max-width: 500px"
                      hide-details
                      rounded
                      chips
                      solo
                    >
                      <template v-slot:item="{ item }">
                        {{ item.communityID.name }}
                        <v-chip class="mx-2">
                          {{ item.name }}
                        </v-chip>
                      </template>
                      <template v-slot:selection="{ item }">
                        {{ item.communityID.name }}
                        <v-chip class="mx-2">
                          {{ item.name }}
                        </v-chip>
                      </template>
                    </v-select>
                    <v-btn @click="addLink" large rounded>Create link</v-btn>
                  </v-toolbar>
                  <v-alert v-show="!links.length" color="warning">
                    <v-icon>warning</v-icon>
                    No data
                  </v-alert>
                  <v-container fluid>
                    <v-row>
                      <v-col v-for="(item, i) of links" :key="i">
                        <v-card @click="signupCodeToClipboard(item.value)">
                          <v-card-title v-if="item.roleID">
                            <v-icon class="mr-2">mdi-link</v-icon>
                            {{ getCommunityNameById(item.roleID.communityID) }}
                            <v-chip class="mx-2">{{ item.roleID.name }}</v-chip>
                          </v-card-title>
                          <v-card-subtitle v-if="item.issuer">
                            Issued by: {{ item.issuer.firstName }}
                            {{ item.issuer.lastName }}
                          </v-card-subtitle>
                          <v-card-text>
                            <v-btn text>
                              {{ item.value }}
                            </v-btn>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <profile-customization />
          </v-tab-item>
          <v-tab-item>
            <distribute-assignments />
          </v-tab-item>
        </v-tabs>
        <v-alert
          v-model="alert"
          transition="slide-y-transition"
          type="error"
          dismissible
        >
          {{ message }}
        </v-alert>
      </div>
      <profile-customization v-else />
      <enabled v-model="clockMenu" :item="clock" />
      <v-dialog v-model="userMenu" width="600px">
        <v-card v-if="user">
          <v-card-title class="display-1">
            Roles of
            {{ user.firstName }} {{ user.lastName }}
            <v-spacer></v-spacer>
            <v-btn icon @click="userMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-chip-group
              v-for="(communityRole, community) in communityRoles()"
              :key="community"
              v-model="enabledRoles[community]"
              @change="chooseActiveRole"
              column
              :mandatory="isActiveRole(communityRole)"
            >
              <v-btn
                @click="chooseActiveRole(enabledRoles[community])"
                :class="isActiveRole(communityRole) ? 'success--text' : ''"
              >
                {{ community }}
              </v-btn>
              <v-divider vertical class="mx-2"></v-divider>
              <v-chip
                v-for="role in communityRole"
                :key="role._id"
                :value="role._id"
                filter
                outlined
              >
                {{ role.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded @click="updateUser">save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="roleMenu" width="800px">
        <v-card v-if="role">
          <v-card-title class="display-1">
            {{ role.name }}
            <v-card-subtitle v-if="role.communityID">
              {{ role.communityID.name }}
            </v-card-subtitle>
            <v-spacer></v-spacer>
            <v-btn icon @click="roleMenu = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text v-if="role.can">
            <v-container class="pt-0">
              <v-row v-for="resource in Object.keys(role.can)" :key="resource">
                <div class="my-auto body-1 text-capitalize">
                  {{ resource }}
                </div>
                <v-divider vertical class="mx-3 my-3"></v-divider>
                <v-checkbox
                  v-for="(permission, i) in [
                    'READ',
                    'CREATE',
                    'UPDATE',
                    'DELETE'
                  ]"
                  :key="i"
                  :input-value="role.can[resource].includes(permission)"
                  :label="permission"
                  disabled
                  class="pr-2"
                ></v-checkbox>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn rounded @click="updateRole">save</v-btn> -->
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="register">
        <v-card>
          <register />
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
// Componets
import DistributeAssignments from "@/components/cms/DistributeAssignments";
import Enabled from "@/components/cms/Enabled";

// Services
import PreferencesService from "@/services/PreferencesService";
import ProfileCustomization from "@/components/customization/ProfileCustomization.vue";
import Login from "@/services/LoginService";
import Register from "./Register";
export default {
  components: {
    ProfileCustomization,
    DistributeAssignments,
    Enabled,
    Register
  },
  data: () => ({
    tab: null,
    register: false,
    user: {},
    role: {},
    link: {},
    enabledRoles: {},
    communities: [],
    roles: [],
    users: [],
    links: [],
    model: [],
    name: "",
    repo: "",
    message: "",
    alert: false,
    clock: null,
    clockMenu: false,
    userMenu: false,
    roleMenu: false,
    loginLoading: false,
    communitiesLoading: false,
    usersLoading: false,
    rolesLoading: false,
    linksLoading: false,
    roleIDActive: null
  }),
  mounted() {
    this.getRoles();
    this.getUsers();
    this.getCommunities();
    this.getLinks();
  },
  created() {
    this.isLoggedIn();
  },
  computed: {
    loading() {
      return (
        this.loginLoading ||
        this.usersLoading ||
        this.rolesLoading ||
        this.communitiesLoading ||
        this.linksLoading
      );
    },
    currentRole() {
      if (!this.isUserLoggedIn) return null;
      const role = this.loggedUser.roleID.find(
        x => x._id == this.loggedUser.roleIDActive
      );
      if (!role) return null;
      return role.name;
    },
    isCurrentRole() {
      return () => false;
    },
    isCurrentCommunity() {
      return () => false;
    },
    isCurrentUser() {
      return () => false;
    },
    linkHeaders() {
      return [
        { text: "ID", value: "_id" },
        { text: "NAME", value: "issuer" }
      ];
    },
    loggedUser() {
      return this.$store.getters.getLoggedUser;
    },
    isUserLoggedIn() {
      return this.$store.getters.getIsUserLoggedin;
    }
  },
  methods: {
    async isLoggedIn() {
      this.loginLoading = true;
      try {
        const res = await Login.isLoggedin();
        if (res.data.success) {
          this.$store.commit("setLoggedUser", res.data.user);
          this.$store.commit("setIsUserLoggedin", true);
        } else {
          this.$router.push("/login");
        }
      } catch {
        this.$router.push("/login");
      } finally {
        this.loginLoading = false;
      }
    },
    chooseActiveRole(role) {
      if (!role) return;
      this.roleIDActive = role;
    },
    isActiveRole(communityRole) {
      return communityRole.some(role => role._id === this.roleIDActive);
    },
    getCommunityNameById(id) {
      const community = this.communities.find(c => c._id === id);
      if (!community) return;
      const { name } = community;
      if (!name) return;
      return name;
    },
    signupCodeToClipboard(signupCode) {
      navigator.clipboard.writeText(signupCode);
      this.$store.commit("alert", {
        text: `Signup code ${signupCode} copied to clipboard`,
        color: "success",
        timeout: 7000
      });
    },
    openUpdateUserMenu(user) {
      this.userMenu = true;
      this.user = Object.assign({}, user);
      this.roleIDActive = this.user.roleIDActive;
      this.enabledRoles = this.communityRoles(user.roleID);
    },
    openUpdateRoleMenu(role) {
      this.roleMenu = true;
      this.role = role;
    },
    activateClock(clock) {
      this.clockMenu = true;
      this.clock = clock;
    },
    communityRoles(roleIDs) {
      const groupBy = key => roles =>
        roles.reduce((keyValue, role) => {
          const value = role[key].name;
          keyValue[value] = !roleIDs
            ? (keyValue[value] || []).concat(role)
            : role._id;
          return keyValue;
        }, {});
      const groupByCommunity = groupBy("communityID");
      return groupByCommunity(roleIDs || this.roles);
    },
    async getCommunities() {
      this.communitiesLoading = true;
      try {
        const res = await PreferencesService.getCommunity();
        this.communities = res.data.data;
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.communitiesLoading = false;
      }
    },
    async addCommunity(data) {
      this.communitiesLoading = true;
      try {
        await PreferencesService.postCommunity(data);
        this.getCommunities();
        this.getRoles();
        this.getUsers();
        this.name = "";
        this.repo = "";
        this.model = [];
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.communitiesLoading = false;
      }
    },
    async deleteCommunity(id) {
      if (!confirm("Are you sure ?")) return;
      try {
        await PreferencesService.deleteCommunity(id);
        this.getCommunities();
        this.getRoles();
        this.getUsers();
        this.getLinks();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      }
    },
    async getRoles() {
      this.rolesLoading = true;
      try {
        const res = await PreferencesService.getRole();
        this.roles = res.data.data;
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.rolesLoading = false;
      }
    },
    async addRole(data) {
      this.rolesLoading = true;
      try {
        await PreferencesService.postRole(data);
        this.getRoles();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.rolesLoading = false;
      }
    },
    async updateRole(id, data) {
      this.rolesLoading = true;
      try {
        await PreferencesService.updateRole(id, data);
        this.getRoles();
        this.getCommunities();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.rolesLoading = false;
      }
    },
    async deleteRole(id) {
      if (!confirm("Are you sure ?")) return;
      this.rolesLoading = false;
      try {
        await PreferencesService.deleteRole(id);
        this.getRoles();
        this.getCommunities();
        this.getUsers();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.rolesLoading = false;
      }
    },
    async getUsers() {
      this.usersLoading = true;
      try {
        const res = await PreferencesService.getUser();
        this.users = res.data.data;
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.usersLoading = false;
      }
    },
    async addUser() {
      this.usersLoading = true;
      try {
        await PreferencesService.postUser(this.user);
        this.getUsers();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.usersLoading = false;
      }
    },
    async updateUser() {
      this.usersLoading = true;
      try {
        await PreferencesService.patchUser(this.user._id, {
          roleIDs: Object.values(this.enabledRoles),
          roleIDActive: this.roleIDActive
        });
        this.getUsers();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.userMenu = false;
        this.usersLoading = false;
      }
    },
    async deleteUser(id) {
      if (!confirm("Are you sure ?")) return;
      this.usersLoading = false;
      try {
        await PreferencesService.deleteUser(id);
        this.getUsers();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.usersLoading = false;
      }
    },
    async getLinks() {
      this.linksLoading = true;
      try {
        const res = await PreferencesService.getLink();
        this.links = res.data.data;
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.linksLoading = false;
      }
    },
    async addLink() {
      this.linksLoading = true;
      try {
        await PreferencesService.postLink(this.link);
        this.getLinks();
      } catch (err) {
        this.alert = true;
        this.message = err.response.data.message;
      } finally {
        this.linksLoading = false;
      }
    }
  }
};
</script>

<template>
  <div>
    <v-toolbar flat height="80" class="title">
      <div class="text-no-wrap pr-5">Test strategy</div>
      <v-divider vertical></v-divider>
      <v-subheader style="line-height: 20px">{{ reportLabel }}</v-subheader>
    </v-toolbar>
    <editor
      :value="value"
      @init="editorInit"
      @input="v => $emit('input', v)"
      :disabled="readOnly"
      :extensions="readOnly ? [] : extensions"
      :card-props="{ flat: true }"
      :toolbar-attributes="{ color: '' }"
      placeholder="Describe your strategy here..."
    >
      <template
        v-if="reportEditor && reportEditor.editor.isActive.table()"
        #toolbar-after="{}"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="deleteTable()"
              class="ml-2"
              v-on="on"
              icon
              :color="
                $vuetify.theme.dark
                  ? reportButtonColorDarkMode
                  : reportButtonColorWhiteMode
              "
            >
              <v-icon>mdi-table-edit</v-icon>
            </v-btn>
          </template>
          <span>Delete table</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="deleteRow()"
              class="ml-2"
              v-on="on"
              icon
              :color="
                $vuetify.theme.dark
                  ? reportButtonColorDarkMode
                  : reportButtonColorWhiteMode
              "
            >
              <v-icon>mdi-table-row-remove</v-icon>
            </v-btn>
          </template>
          <span>Delete row</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              @click="deleteColumn()"
              class="ml-2"
              v-on="on"
              icon
              :color="
                $vuetify.theme.dark
                  ? reportButtonColorDarkMode
                  : reportButtonColorWhiteMode
              "
            >
              <v-icon>mdi-table-column-remove</v-icon>
            </v-btn>
          </template>
          <span>Delete column</span>
        </v-tooltip>
      </template>
    </editor>
  </div>
</template>

<script>
import "tiptap-vuetify/dist/main.css";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
  Image,
  TableHeader,
  TableCell,
  TableRow,
  Table
} from "tiptap-vuetify";

export default {
  data() {
    return {
      reportButtonColorLightMode: "rgba(0, 0, 0, 0.54)",
      reportButtonColorDarkMode: "rgba(255, 255, 255)",
      extensions: [
        History,
        Blockquote,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3]
            }
          }
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
        Image,
        TableHeader,
        TableCell,
        TableRow,
        Table
      ],
      reportEditor: null,
      observer: null
    };
  },
  props: {
    value: String,
    reportLabel: String,
    readOnly: Boolean
  },
  components: { Editor: TiptapVuetify },
  methods: {
    deleteRow() {
      if (
        !confirm(
          "Are you sure you want to delete the table row your cursor is currently on ?"
        )
      )
        return;
      if (!this.reportEditor?.editor.commands.deleteRow()) {
        this.deleteTable();
      }
    },
    deleteColumn() {
      if (
        !confirm(
          "Are you sure you want to delete the table column your cursor is currently on ?"
        )
      )
        return;
      if (!this.reportEditor?.editor.commands.deleteColumn()) {
        this.deleteTable();
      }
    },
    deleteTable() {
      if (
        !confirm(
          "Are you sure you want to delete the table your cursor is currently on ?"
        )
      )
        return;
      this.reportEditor?.editor.commands.deleteTable();
    },
    editorInit(editor) {
      this.reportEditor = editor;
      this.observer = this.createObserver();
      this.observer.observe(document.body, { childList: true, subtree: true });
    },
    createObserver() {
      return new MutationObserver(records => {
        for (const record of records) {
          if (record && record.type === "childList") {
            const node = record.addedNodes[0];
            if (node && node.textContent === " TODO") {
              node.textContent = "Table";
            }
          }
        }
      });
    }
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
};
</script>
<style>
.ProseMirror {
  /* iOS fix */
  -webkit-user-select: text;
  user-select: text;
}
.theme--dark .ProseMirror {
  color: white;
}
.theme--light .ProseMirror {
  color: black;
}
</style>

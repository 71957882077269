var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('v-card',[_c('v-img',{attrs:{"src":require("../../assets/update_profile_pic.jpg"),"height":"25vh"}}),_c('v-card-title',[_vm._v(" Profile of "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-card-text',[_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(!_vm.passwordChange),expression:"!passwordChange"}],ref:"form"},[_c('v-text-field',{attrs:{"disabled":"","label":"Username","rules":[_vm.rules.required]},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}}),_c('v-text-field',{attrs:{"label":"Email","rules":[_vm.rules.required, _vm.rules.validEmail]},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-container',[_c('v-row',[_c('v-text-field',{attrs:{"label":"First name","rules":[
                    _vm.rules.required,
                    _vm.rules.max48Chars,
                    _vm.rules.alphaSpace
                  ]},model:{value:(_vm.user.firstName),callback:function ($$v) {_vm.$set(_vm.user, "firstName", $$v)},expression:"user.firstName"}}),_c('v-text-field',{attrs:{"label":"Last name","rules":[
                    _vm.rules.required,
                    _vm.rules.max48Chars,
                    _vm.rules.alphaSpace
                  ]},model:{value:(_vm.user.lastName),callback:function ($$v) {_vm.$set(_vm.user, "lastName", $$v)},expression:"user.lastName"}})],1)],1)],1),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.passwordChange),expression:"passwordChange"}],ref:"passwordForm"},[_c('v-text-field',{attrs:{"type":"password","label":"Current Password","rules":[_vm.rules.required, _vm.rules.min8Chars, _vm.rules.max72Chars]},model:{value:(_vm.password.current),callback:function ($$v) {_vm.$set(_vm.password, "current", $$v)},expression:"password.current"}}),_c('v-text-field',{attrs:{"type":"password","label":"New Password","rules":[_vm.rules.required, _vm.rules.min8Chars, _vm.rules.max72Chars]},model:{value:(_vm.password.new),callback:function ($$v) {_vm.$set(_vm.password, "new", $$v)},expression:"password.new"}}),_c('v-text-field',{attrs:{"type":"password","label":"Confirm new password","rules":[_vm.rules.required, _vm.rules.min8Chars, _vm.rules.max72Chars]},model:{value:(_vm.password.confirmation),callback:function ($$v) {_vm.$set(_vm.password, "confirmation", $$v)},expression:"password.confirmation"}})],1),_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.changePassword}},[_c('v-icon',{staticClass:"pr-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.passwordChange ? "mdi-arrow-left" : "mdi-key-variant")+" ")]),_vm._v(" "+_vm._s(_vm.passwordChange ? "Back" : "Change password")+" ")],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"x-large":"","color":"success"},on:{"click":_vm.updateUserProfile}},[_vm._v(" Update "+_vm._s(_vm.passwordChange ? "password" : "profile")+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"title",attrs:{"flat":"","height":"80"}},[_c('div',{staticClass:"text-no-wrap pr-5"},[_vm._v("Test strategy")]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-subheader',{staticStyle:{"line-height":"20px"}},[_vm._v(_vm._s(_vm.reportLabel))])],1),_c('editor',{attrs:{"value":_vm.value,"disabled":_vm.readOnly,"extensions":_vm.readOnly ? [] : _vm.extensions,"card-props":{ flat: true },"toolbar-attributes":{ color: '' },"placeholder":"Describe your strategy here..."},on:{"init":_vm.editorInit,"input":function (v) { return _vm.$emit('input', v); }},scopedSlots:_vm._u([(_vm.reportEditor && _vm.reportEditor.editor.isActive.table())?{key:"toolbar-after",fn:function(ref){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":_vm.$vuetify.theme.dark
                ? _vm.reportButtonColorDarkMode
                : _vm.reportButtonColorWhiteMode},on:{"click":function($event){return _vm.deleteTable()}}},on),[_c('v-icon',[_vm._v("mdi-table-edit")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete table")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":_vm.$vuetify.theme.dark
                ? _vm.reportButtonColorDarkMode
                : _vm.reportButtonColorWhiteMode},on:{"click":function($event){return _vm.deleteRow()}}},on),[_c('v-icon',[_vm._v("mdi-table-row-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete row")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","color":_vm.$vuetify.theme.dark
                ? _vm.reportButtonColorDarkMode
                : _vm.reportButtonColorWhiteMode},on:{"click":function($event){return _vm.deleteColumn()}}},on),[_c('v-icon',[_vm._v("mdi-table-column-remove")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete column")])])]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
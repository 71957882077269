// https://vuex.vuejs.org/en/getters.html

export default {
    getLoggedUser: state => state.loggedUser,
    getIsUserLoggedin: state => state.isUserLoggedin,
    getAssignmentTree: state => state.tree,
    getRootName: state => '/' + state.tree[0].name.replace(/\s+/g, "--"),
    getNotViewedLogs: state => function (fileId) {
        if (fileId !== null && Object.prototype.hasOwnProperty.call(state.notViewedLogs, fileId.toString())) {
            return state.notViewedLogs[fileId.toString()];
        } return 0;
    },
    getUnsavedChangesToSubmission: state => state.unsavedChangesToSubmission,
}

<template>
  <v-menu
    bottom
    left
    offset-y
    transition="scale-transition"
    origin="top right"
    ref="menuRef"
    :max-width="maxWidth"
    :close-on-content-click="true"
  >
    <template v-slot:activator="{ on: menu }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn
            v-if="isLogged"
            v-on="{ ...tooltip, ...menu }"
            class="px-0"
            x-large
            text
          >
            <div>
              <v-avatar class="primary darken-2" size="42">
                <span
                  v-if="$store.getters.getLoggedUser.username"
                  class="white--text"
                >
                  {{ $store.getters.getLoggedUser.username.slice(0, 2) }}
                </span>
              </v-avatar>
              <v-icon color="primary" size="21">mdi-chevron-down</v-icon>
            </div>
          </v-btn>
          <v-btn v-else icon v-on="{ ...tooltip, ...menu }">
            <v-icon>settings</v-icon>
          </v-btn>
        </template>
        <span v-if="isLogged">Profile</span>
        <span v-else>Settings</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-container pa-0>
        <v-list shaped>
          <v-list-item v-if="isLogged" class="px-2">
            <v-list-item-content>
              <v-list-item-title class="title">
                {{ $store.getters.getLoggedUser.username }}
              </v-list-item-title>

              <v-list-item-subtitle>
                {{ $store.getters.getLoggedUser.email }}
              </v-list-item-subtitle>
              <profile-switcher />
            </v-list-item-content>
          </v-list-item>
          <v-divider class="my-1" v-if="isLogged"></v-divider>
          <v-list-item v-if="isLogged">
            <v-list-item-title>Settings</v-list-item-title>
          </v-list-item>
          <v-list-item @click="dark = !dark" link>
            <v-list-item-icon>
              <v-icon :color="dark ? 'white' : 'black'">
                mdi-invert-colors
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Switch Color mode</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="isLogged" @click="perform('preferences')">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Preferences</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="isLogged" @click="perform('logout')" link>
            <v-list-item-icon>
              <v-icon color="red">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-card>
  </v-menu>
</template>
<script>
import ProfileSwitcher from "./ProfileSwitcher";

export default {
  components: {
    ProfileSwitcher
  },
  computed: {
    isLogged() {
      return this.$store.getters.getIsUserLoggedin;
    },
    roles() {
      return this.$store.getters.getLoggedUser.roleID;
      },
    maxWidth() {
      if(this.roles.length < 2) return "300";
      let longestRoleCommunityCombo = "";
      for(const role of this.roles) {
        let currentCombo = `${role.name} in ${role.communityID.name}`;
        if(currentCombo.length > longestRoleCommunityCombo.length) {
          longestRoleCommunityCombo = currentCombo;
        }
      }
      return `${longestRoleCommunityCombo.length * 12}`;
    },
    dark: {
      get() {
        return this.$vuetify.theme.dark;
      },
      set(val) {
        this.$vuetify.theme.dark = val;
      }
    }
  },
  methods: {
    perform(action) {
      // Deactivate menu
      this.$refs.menuRef.isActive = false;
      this.$emit(action);
    }
  }
};
</script>
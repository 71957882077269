<template>
  <v-container>
    <v-row justify="center">
      <v-col md="6">
        <v-card>
          <v-img
            src="../../assets/update_profile_pic.jpg"
            height="25vh"
          ></v-img>
          <v-card-title>
            Profile of {{ user.firstName }} {{ user.lastName }}
          </v-card-title>
          <v-card-text>
            <v-form v-show="!passwordChange" ref="form">
              <v-text-field
                disabled
                label="Username"
                v-model="user.username"
                :rules="[rules.required]"
              ></v-text-field>
              <v-text-field
                label="Email"
                v-model="user.email"
                :rules="[rules.required, rules.validEmail]"
              ></v-text-field>
              <v-container>
                <v-row>
                  <v-text-field
                    v-model="user.firstName"
                    label="First name"
                    :rules="[
                      rules.required,
                      rules.max48Chars,
                      rules.alphaSpace
                    ]"
                  ></v-text-field>
                  <v-text-field
                    v-model="user.lastName"
                    label="Last name"
                    :rules="[
                      rules.required,
                      rules.max48Chars,
                      rules.alphaSpace
                    ]"
                  ></v-text-field>
                </v-row>
              </v-container>
            </v-form>
            <v-form v-show="passwordChange" ref="passwordForm">
              <v-text-field
                v-model="password.current"
                type="password"
                label="Current Password"
                :rules="[rules.required, rules.min8Chars, rules.max72Chars]"
              ></v-text-field>
              <v-text-field
                v-model="password.new"
                type="password"
                label="New Password"
                :rules="[rules.required, rules.min8Chars, rules.max72Chars]"
              ></v-text-field>
              <v-text-field
                v-model="password.confirmation"
                type="password"
                label="Confirm new password"
                :rules="[rules.required, rules.min8Chars, rules.max72Chars]"
              ></v-text-field>
            </v-form>
            <v-container>
              <v-row>
                <v-spacer />
                <v-btn @click="changePassword" text>
                  <v-icon small class="pr-2">
                    {{ passwordChange ? "mdi-arrow-left" : "mdi-key-variant" }}
                  </v-icon>
                  {{ passwordChange ? "Back" : "Change password" }}
                </v-btn>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="updateUserProfile" x-large color="success">
              Update {{ passwordChange ? "password" : "profile" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PreferencesService from "@/services/PreferencesService";
import isEmail from "validator/lib/isEmail";

export default {
  data() {
    return {
      user: {},
      passwordChange: false,
      password: {
        current: null,
        new: null,
        confirmation: null
      },
      rules: {
        required: v => !!v || "This field is required.",
        min8Chars: v => v?.length >= 8 || "Minimum 8 characters",
        max48Chars: v => v?.length <= 48 || "Maximum 48 characters",
        max72Chars: v => v?.length <= 72 || "Maximum 72 characters",
        validEmail: v => isEmail(v) || "Please enter a valid email address",
        alphaSpace: v =>
          /^([a-zA-Z ]+)$/.test(v) || "Only letters and spaces allowed"
      }
    };
  },
  created() {
    this.user = Object.assign({}, this.$store.getters.getLoggedUser);
  },
  methods: {
    changePassword() {
      this.passwordChange = !this.passwordChange;
      this.$refs.passwordForm.reset();
    },
    async updateUserProfile() {
      if (!this.$refs.form.validate()) return;
      if (this.passwordChange && !this.$refs.passwordForm.validate()) return;
      Object.assign(this.user, { password: this.password });
      try {
        const res = await PreferencesService.updateUserProfile(this.user);
        let text = "Your profile information was updated successfully.";
        if (res.data.passwordChanged) {
          text +=
            " Please login with your new password to continue using the applicaiton.";

          this.$router.push("/login");
        }
        this.$store.commit("alert", {
          text,
          color: "success",
          timeout: 8000
        });
      } catch (err) {
        this.$store.commit("alert", {
          text: err.response.data.message,
          color: "error",
          timeout: 8000
        });
      }
    }
  }
};
</script>
